import {HmsPush} from '@hmscore/ionic-native-hms-push'
import { Capacitor } from '@capacitor/core';

import bus from '@/plugins/bus'
import store from '@/store'
import rest from "@/plugins/rest"
import errorListiner from '@/plugins/error.listiner'
import pushGoogle from '@/plugins/capacitor.push'

const push = {

    currentTopic: "",

    init() {

        this.register();

        //получение data-уведомления
        window.HmsPushEvent.onRemoteMessageReceived((result) => {

            let data = typeof result.msg.data === 'object' ? result.msg.data: JSON.parse(result.msg.data);

            //покажем сообщение в области уведомлений
            bus.emit('OKRAINA_NOTIFICATION_E_SHOW', data);

        });

        //открытие приложения по клику на уведомение
        window.HmsPushEvent.onNotificationOpenedApp((result) => {

            //если было смешанное push (notification+data), то данные сразу в extras
            //иначе если было data push, то данные указаны в extras.notification.data
            let data = result.extras.notification && result.extras.notification.data ? result.extras.notification.data : result.extras
            pushGoogle.onPushClick(data);
        });
        
    },

    /**
     * Производит регистрацию и проверку доступа
     */
    async register() {

        HmsPush.setBackgroundFile('public/push_background/hms.js');

        //инициализация
        HmsPush.init().then(() => {

            console.log("HmsPush inited");

            //получаем токен
            HmsPush.getToken().then((result) => {

                console.log("HmsPush token received");

                //обновляем токен в приложении
                this.onTokenRefresh(result);
            });

        }).catch(error => {
            errorListiner.onError(error);
            console.error("HmsPush int error")
            console.error(error)
        })
        
        //подписываемся на запрос удаления токена с сервера
        bus.on("OKRAINA_PUSH_E_DELETE_TOKEN_FROM_SERVER", this.deleteTokenFromServer);
        //подписываемся на запрос сохранения токена на сервере
        bus.on("OKRAINA_PUSH_E_SAVE_TOKEN_TO_SERVER", this.saveTokenToServer);
        //подписываемся на запрос подписки на топик
        bus.on("OKRAINA_PUSH_E_SUBSCRIBE", this.subscribe);
        //подписываемся на запрос отписки от топика
        bus.on("OKRAINA_PUSH_E_UNSUBSCRIBE", this.unSubscribe);
    },

    /**
    * Обработка изменения токена
    */
    onTokenRefresh(token) {

        let oldToken = store.getters.getPushToken;

        //сохраним токен в текущем состоянии приложения
        store.commit('setPushToken', token);

        //сохраним токен на сервере
        this.saveTokenToServer(oldToken);

        //подпишемся на новости/акции
        this.subscribe();

    },

    /**
    * Сохраняет токен на сервере
    */
    saveTokenToServer() {

        //сохраняем только в случае если пользователь авторизован и имеется FCM токен
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.add", {
                method: 'post',
                data: {
                    TOKEN: store.getters.getPushToken,
                    PLATFORM: Capacitor.getPlatform(),
                    TYPE: "HMS"
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.SUCCESS) {
                        errorListiner.onError({
                            message: "Ошибка сохранения push токена: " +data.ERROR_TEXT
                        });
                    }

                })
                .catch(() => {
                    //в тихом режиме не обрабатываем ошибку
                });
        }

    },

    /**
    * Удаление
    */
    deleteTokenFromServer() {

        //только если токен имеется и пользователь авторизован
        if (store.getters.getPushToken && store.getters.isAuthorized) {

            rest.call("push.token.delete", {
                method: 'post',
                data: {
                    TOKEN: store.getters.getPushToken
                }
            }, true) //тихий режим
                .then(data => {

                    if (!data.SUCCESS) {
                        errorListiner.onError({
                            message: "Ошибка удаления push токена: " +data.ERROR_TEXT
                        });
                    }

                })
        }

    },

    /**
     * Полуает топик
     */
    getTopic() {
        
        //создаём прамис, чтобы вызывающий компонент ждал
        return new Promise(resolve => {

            //при переключении региона настройки сайта будут сбрасываться и топик опять будет пустой
            //что будет приводить к ожиданию топика и дальше по кругу
            let topic = store.getters.getPushTopic;
            
            //если настройки уже прогрузились и топик известен
            if(topic) {
                resolve(topic);
            } else {

                //следим за изменением топика
                const watcher = store.watch((state,getters) => { return getters.getPushTopic }, (newVal) => {
                    //если поменялся 
                    if (newVal) {
                        //прекращаем отслеживание
                        watcher();
                        resolve(newVal);
                    }
                });
            }

        });
    },

    /**
     * Подписка на топик
     */
    subscribe() {
        push.getTopic().then((topic) => {
            
            push.subscribeToImmediatly(topic);
            //запоминаем топик, чтобы потом можно было отписаться, не получая из store топик
            //ведь получение getTopic() идёт через promise, что потенциально может создать проблемы
            push.currentTopic = topic;
        });

    },

    /**
     * Подписка на топик
     */
    subscribeToImmediatly(topic) {

        //используем другой плагин - FCM
        HmsPush.subscribe(topic)
        .catch((err) => console.log(err));
            
    },

    /**
     * Отписка от топика
     */
    unSubscribe() {

        if(push.currentTopic) {
            push.unSubscribeImmediatly(push.currentTopic);
        }

    },

    /**
     * Отписка от топика
     */
    unSubscribeImmediatly(topic) {

        HmsPush.unsubscribe(topic).catch((err) => console.log(err));
    },

}

export default push;