<template>
    <okraina-view :class="['okraina-v-cart', items.length > 0 ? 'cart-not-empty' : '']" @refresh="refresh" ref="view"
        :showBack="false">

        <template v-slot:content>
            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading" />
            </transition>

            <template v-if="!loading">

                <transition>
                    <okraina-overlay v-show="showOverlay" :fixed="true" />
                </transition>

                <div class="title-wrap">
                    <div class="title">Корзина</div>
                    <span v-if="items.length > 0" class="share fi fi-share" @click="onShareClick"></span>
                </div>

                <div class="delimiter"></div>

                <!--Если корзина не пуста-->
                <template v-if="items.length > 0">

                    <!--Кол-во товаров -->
                    <okraina-cart-counter :items="items" :products="products" />

                    <!--Уровень кешбэка-->
                    <okraina-cart-bonus-status />

                    <!--Мин сумма-->
                    <div class="sum-error" v-if="orderMinSum > 0 && sum < orderMinSum">
                        <div class="bg"></div>
                        <div class="inner">
                            До минимального заказа товаров в корзине должно быть минимум на
                            <span v-html="tools.formatPrice(this.orderMinSum, {
        includeDecimals: false,
        supCurrency: false,
    })"></span>
                        </div>

                    </div>

                    <!--Позиции-->
                    <div class="items">
                        <transition-group name="fade">
                            <okraina-cart-item v-for="item in items" :key="item.FIELDS.ID" :item="item"
                                :product="getProductByItem(item)" @deleted="onItemDeleted" />
                        </transition-group>
                    </div>

                    <!--Поделиться корзиной-->
                    <div class="share-wrap">
                        <div class="share" @click="onShareClick">
                            <span class="icon fi fi-share"></span>
                            Поделиться корзиной
                        </div>
                    </div>

                    <!--Отсутствуют -->
                    <div class="not-available" v-if="not_available.items.length > 0">
                        <div class="na-title">
                            Недоступно к заказу ({{ not_available.items.length }})
                        </div>

                        <div class="items">
                            <transition-group name="fade">
                                <okraina-cart-item v-for="item in not_available.items" :key="item.FIELDS.ID"
                                    :item="item" :product="getProductByItem(item)"
                                    @deleted="onItemNotAvailableDeleted" />
                            </transition-group>
                        </div>
                    </div>

                    <template v-if="banners.length > 0">
                        <div class="banners">
                            <h2>Акции</h2>
                            <okraina-slider-main-banners :items="banners" />
                        </div>
                    </template>

                    <!--Использовать бонусы-->
                    <okraina-cart-bonus-use v-if="isAuthorized && isBonusEnabled" :price="discountPrice" />

                    <!--Промокод-->
                    <okraina-cart-coupons v-if="isCouponsEnabled" :items="coupons" @updated="onCouponsUpdated" />

                    <!--Итого-->
                    <okraina-cart-total :items="items" :deliveryPrice="deliveryPrice" :cashback="cashback"
                        :valentines="valentines" />

                    <div class="padding-order"></div>


                    <!--Оформить заказ-->
                    <okraina-fixed-panel>
                        <okraina-cart-order :offer="offer" :items="items" :products="products"
                            :deliveryPrice="deliveryPrice" :disabled="!canOrder" />
                    </okraina-fixed-panel>


                </template>

                <!--Иначе корзина пуста-->
                <template v-else>
                    <div class="cart-empty">
                        <div class="picture" :style="{ 'background-image': 'url(' + imageCartEmpty + ')' }"></div>
                        <div class="caption">Ваша корзина пуста</div>
                        <div class="details">
                            Перейдите в каталог или воспользуйтесь поиском, чтобы продолжить свои покупки
                        </div>
                    </div>
                </template>

            </template>

        </template>

        <template v-slot:footer>
            <template v-if="!loading">
                <template v-if="items.length == 0">
                    <div class="catalog-btn-wrap">
                        <a class="btn btn1 btn-block" href="/catalog/" v-ripple
                            @click.prevent="goto('/catalog/')">Перейти в
                            каталог</a>
                    </div>
                </template>
            </template>
        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCartCounter from "@/components/cart/counter.vue"
import okrainaCartItem from "@/components/cart/item.vue"
import okrainaCartTotal from "@/components/cart/total.vue"
import okrainaCartBonusStatus from "@/components/cart/bonus.status.vue"
import okrainaCartBonusUse from "@/components/cart/bonus.use.vue"
import okrainaCartOrder from "@/components/cart/order.vue"
import okrainaCartCoupons from "@/components/cart/coupons.vue"
import okrainaFixedPanel from '@/components/fixed.panel.vue'
import okrainaSliderMainBanners from "@/components/sliders/main.banners.vue";
import okrainaOverlay from "@/components/overlay.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";
import moment from "moment";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/cart.js";
import store from '@/store'

import { mapGetters } from "vuex";

import imageCartEmptyPath from "@/assets/images/cart-empty.svg";


export default {
    name: "okraina-v-cart",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaCartItem,
        okrainaCartCounter,
        okrainaCartTotal,
        okrainaCartBonusStatus,
        okrainaFixedPanel,
        okrainaCartOrder,
        okrainaCartCoupons,
        okrainaCartBonusUse,
        okrainaSliderMainBanners,
        okrainaOverlay,
    },
    data() {
        return {
            loading: true,
            items: [],
            offer: false,
            products: [],
            deliveryPrice: 0,
            cashback: 0,
            saleCouponSum: 0,
            coupons: [],
            timer: 0,
            nearestDelivery: false,
            valentines: 0,
            imageCartEmpty: imageCartEmptyPath,
            moment: moment,
            tools: tools,
            banners: [],
            showOverlay: false,

            not_available: {
                items: [],
                products: []
            }
        };
    },

    computed: {
        ...mapGetters({
            isAuthorized: "isAuthorized",
            basket: "getBasket",
            siteSettings: "getSiteSettings"
        }),

        deliveryToday() {
            return this.daysToDelivery == 0
        },

        deliveryTomorrow() {
            return this.daysToDelivery == 1
        },

        daysToDelivery() {
            if (this.nearestDelivery == false) {
                return 0;
            }

            return moment(this.nearestDelivery.DATE, 'DD.MM.YYYY').diff(moment().startOf('day'), 'days');
        },

        /**
         * Использовать купоны разрешено
         */
        isCouponsEnabled() {
            return this.siteSettings ? this.siteSettings.COUPONS_ENABLED == "Y" : false;
        },

        /**
         * Оплата бонусами включена
         */
        isBonusEnabled() {
            return this.siteSettings.ORDER_PAY_FROM_ACCOUNT == "Y";
        },

        orderMinSum() {
            let min = this.siteSettings ? parseInt(this.siteSettings.ORDER_MIN_SUM) : 0
            //Учёт оплаты сертикатом правила работы с корзиной
            return min - this.saleCouponSum;
        },

        discountPrice() {
            let value = this.items.reduce(function (sum, item) {
                return sum + item.FIELDS.QUANTITY * item.PRICE.DISCOUNT_VALUE;
            }, 0);

            return value + this.deliveryPrice;
        },

        sum() {
            return this.discountPrice;
        },

        canOrder() {
            if (this.orderMinSum > 0 && this.sum < this.orderMinSum) {
                return false
            }
            return true;
        },

        basketId() {
            //берём первый попавшися товар в корзине
            let item = this.items.find(() => true);

            return item ? item.FIELDS.FUSER_ID : 0;
        }
    },
    watch: {
        basket: {
            handler() {

                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = 0;
                }

                //то запустим таймер, чтобы подождть пока пользователь перестанет "тыкать"
                this.showOverlay = true;
                this.loadData(() => { this.showOverlay = false });

            },
            deep: true
        }
    },

    methods: {

        /**
         * Получает данные для запросоы
         */
        getBatchRequest() {

            //рассчитываем множитель для ресайза баннеров
            let multiply = 2;
            if (window.screen.width > 400 && window.screen.width < 700) {
                multiply = 3;
            } else if (window.screen.width > 700) {
                multiply = 4;
            }

            let requests = {
                "basket": {
                    CLASS: "basket",
                    METHOD: "getlist",
                    PARAMS: {
                        INCLUDE_GIFTS: "Y",
                        INCLUDE_OFFERS: "Y",
                        INCLUDE_COUPON_SUM: "Y",
                        RESIZE_OPTIONS: tools.getProductListResizeOptions(),
                    },
                },
                "not_available": {
                    CLASS: "basket",
                    METHOD: "getlist",
                    PARAMS: {
                        NOT_AVAILABLE: "Y",
                        RESIZE_OPTIONS: tools.getProductListResizeOptions(),
                    },
                },
                "banners": {
                    CLASS: "slides",
                    METHOD: "main.getlist",
                    PARAMS: {
                        FILTER: {
                            "!PROPERTY_MOBILE_SHOW_IN_CART": false,
                        },
                        RESIZE_OPTIONS: {
                            TYPE: "PROPORTIONAL",
                            WIDTH: 300 * multiply,
                            HEIGHT: 110 * multiply
                        }
                    }
                }
            };
            if (this.isCouponsEnabled) {
                requests.coupons = {
                    CLASS: "coupon",
                    METHOD: "getlist",
                };
            }

            if (this.isBonusEnabled && this.isAuthorized) {
                requests.bonus = {
                    CLASS: "bonus",
                    METHOD: "balance",
                };
            }

            return requests;
        },

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .batch(this.getBatchRequest()).then((results) => {
                    this.onSuccessLoad(results);
                })
                .finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Обработка загрузки данных
         */
        onSuccessLoad(results) {
            for (var key in results) {
                let data = results[key];
                if (data.SUCCESS) {
                    //корзина
                    if (key == "basket") {
                        //убираем отложенные
                        this.items = data.ITEMS.filter(i => i.FIELDS.DELAY != 'Y');
                        this.offer = data.OFFER ? data.OFFER : false;
                        this.products = data.PRODUCTS;
                        this.deliveryPrice = data.DELIVERY_PRICE;
                        this.cashback = data.CASHBACK;
                        this.saleCouponSum = data.SALE_COUPON_SUM ? data.SALE_COUPON_SUM : 0;
                        this.nearestDelivery = data.NEAREST_DELIVERY;
                        this.valentines = data.VALENTINES ? data.VALENTINES : 0;
                    }
                    //отсутствующие товары
                    else if (key == "not_available") {
                        this.not_available = {
                            items: data.ITEMS,
                            products: data.PRODUCTS
                        };
                    }
                    //купоны
                    else if (key == "coupons") {
                        this.coupons = data.ITEMS;
                    }
                    //баланс бонусов
                    else if (key == "bonus") {
                        this.$store.commit('setBonus', data.VALUE);
                    }
                    //баннеры для корзины
                    else if (key == "banners") {
                        this.banners = data.ITEMS;
                    }

                } else {
                    //показываем сообщение об ошибке
                    bus.emit('OKRAINA_MESSAGE_E_SHOW', { message: data.ERROR_TEXT });
                    return;
                }
            }
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Обработка обновления купонов
         */
        onCouponsUpdated() {
            this.loadData(() => { });
        },

        /**
         * Получает по позиции корзины товар
         */
        getProductByItem(item) {
            let product = this.products.find(p => p.FIELDS.ID == item.FIELDS.PRODUCT_ID);
            if (product) {
                return product;
            }

            product = this.not_available.products.find(p => p.FIELDS.ID == item.FIELDS.PRODUCT_ID);
            return product;
        },

        /**
         * Обработка удаления позиции
         */
        onItemDeleted(id) {
            let item = this.items.find(i => i.FIELDS.ID == id);
            if (!item) {
                return;
            }
            var index = this.items.indexOf(item);
            if (index !== -1) {
                this.items.splice(index, 1);
            }
        },

        /**
        * Обработка удаления позиции (отсутствующие)
        */
        onItemNotAvailableDeleted(id) {
            console.log("onItemNotAvailableDeleted")
            let item = this.not_available.items.find(i => i.FIELDS.ID == id);
            if (!item) {
                return;
            }
            var index = this.not_available.items.indexOf(item);
            console.log(index)
            if (index !== -1) {
                console.log("splice")
                this.not_available.items.splice(index, 1);
            }
        },

        /**
         * Переход на указанный URL
         */
        goto(url) {
            let vm = this;
            setTimeout(() => {
                vm.$router.push({ path: url });
            }, 500);
        },

        /**
         * Клик по "Поделиться корзиной"
         */
        onShareClick() {

            bus.emit("OKRAINA_APP_E_SHARE", {
                title: 'Поделиться',
                text: 'С Вами поделились корзиной',
                url: 'https://' + this.$store.getters.getServer + "/catalog/sharebasket/?basket=" + this.basketId,
                dialogTitle: 'Поделиться',
            });
        }
    },

    created() {
        this.loadData();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        //запишем мета данные страницы
        meta.write(metaTemplates);

        //отправляем хит в BigData, чтобы получить хэш посетителя
        store.dispatch("bigdata/hit");

        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    mounted() {
        bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
    },

    beforeUnmount() {
        bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
    },
}
</script>

<style lang="scss">
.okraina-v-cart {

    .view-content {
        overflow: hidden;
    }

    .title-wrap {
        position: relative;

        .title {
            font-size: 20px;
            line-height: 1;
            color: var(--color-text-title);
            padding: 20px 0px;
        }

        .share {
            position: absolute;
            top: 50%;
            right: 0px;
            padding: 10px;
            transform: translateY(-50%);
            color: var(--color-color1);
            font-size: 18px;
        }
    }


    .delimiter {
        margin-bottom: 10px;
        height: 1px;
        background-color: rgba(var(--color-text-primary-rgb), 0.1);
    }

    .items {
        margin-bottom: 35px;
    }

    .not-available {
        .na-title {
            font-weight: 600;
            font-size: 18px;
            color: var(--color-text-dark);
            padding: 5px 0px 5px 8px;
            margin-bottom: 25px;
        }
    }

    .nearest-delivery {
        margin-top: 35px;
        margin-bottom: 20px;
        text-align: center;

        font-weight: 600;
        font-size: 18px;
        color: var(--color-text-title);
    }

    .cart-empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        text-align: center;

        .picture {
            margin: 0px auto 35px;
            width: 111px;
            height: 98px;
            background-size: contain;
            background-position: center center;
            transform: translateX(-10px);
        }

        .caption {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 15px;
        }

        .details {
            font-size: 13px;
        }
    }

    .catalog-btn-wrap {
        margin-bottom: 10px;
        text-align: center;

        .btn {
            letter-spacing: 0.75px;
            max-width: 295px;
            margin: 0px auto;
        }

    }

    .sum-error {
        position: relative;
        margin: 20px 0px;
        padding: 12px 20px;

        overflow: hidden;
        text-align: center;
        border-radius: 24px;

        .bg {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background: rgba(var(--color-error-rgb), 0.05);
        }

        .inner {
            position: relative;
            z-index: 2;
            font-weight: 500;
            font-size: 12px;
            color: var(--color-error-rgb);
            line-height: 1.2;
        }


        .okraina-price {
            color: inherit;
        }
    }

    .banners {

        h2 {
            padding: 0px 16px;
        }
    }

    .padding-order {
        height: 60px;
    }

    .share-wrap {
        margin-top: -15px;
        margin-bottom: 20px;
        padding-left: 8px;


        .share {
            position: relative;
            display: inline-block;
            padding: 10px 0px 10px 25px;
            cursor: pointer;
            line-height: 1;
            font-weight: 600;


            .icon {
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                color: var(--color-color1);
                font-size: 16px;
            }
        }
    }



}
</style>
