import store from '@/store'

const tools = {
    fancybox: false,
    fancyboxWatchClose: false,

    formatPrice(price, options = {}) {

        options = Object.assign({
            includeDecimals: true,
            supDecimals: true,
            includeCurrency: true,
            supCurrency: true
        }, options);

        let int = Math.floor(price);
        let part = Math.round((price - int) * 100, 0);

        if (part < 10) {
            part = "0" + part;
        }

        if (part == 0) {
            part = "00";
        } else if (part == 100) {
            part = "00";
            int += 1;
        }

        let value = "<span class='okraina-price'>" + this.number_format(int, 0, ".", " ");
        if (options.includeDecimals) {
            if (options.supDecimals) {
                value += ".<sub>" + part + "</sub>";
            } else {
                value += "." + part;
            }
        }

        if (options.includeCurrency) {
            if (options.supCurrency) {
                value += " <sub>&#x20bd;</sub>";
            } else {
                value += " &#x20bd;";
            }
        }

        value += "</span>";

        return value;
    },

    number_format(number, decimals, dec_point, thousands_point) {


        if (!decimals) {
            var len = number.toString().split('.').length;
            decimals = len > 1 ? len : 0;
        }

        if (!dec_point) {
            dec_point = '.';
        }

        if (!thousands_point) {
            thousands_point = ',';
        }

        number = parseFloat(number).toFixed(decimals);

        number = number.replace(".", dec_point);

        var splitNum = number.split(dec_point);
        splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
        number = splitNum.join(dec_point);

        return number;
    },

    /**
     * Выводит словоформу по указанному количеству (например, "1 товар"/"4 товара"/"55 товаров")
     * @param int count колчиество
     * @param array arWordForms массив:
     * 0 - словоформа для кол-ва = 1, 21, 31, 42, ..., 101, 111, и т.д
     * 1 - словоформа для кол-ва от 2 до 4, от 22 до 24, 32 до 34, ..., 
     * 2 - словоформа для кол-ва либо 0 либо 10 до 20, либо от 5 до 9, от 25 до 29, от 35 до 39, ....
     */
    countWord(count,arWordForms) {
        let f = 0;
        
        let tCount = count;
        //если кол-во более 100
        if(tCount >=100) {
            
            //то возьмём последние 2 цифры для анализа
            let toText = tCount.toString(); 
            var last = toText.slice(-2);
            tCount = +(last);
        }

        if(tCount == 0 || tCount >= 10 && tCount <=20) {
            f = 3;
        } else {
            
            //последняя цифра для анализа
            let toText = tCount.toString(); 
            let last = toText.slice(-1);

            if(last == 1) {
                f = 1;
            } else if (last >=2 && last <=4) {
                f = 2;
            } else {
                f = 3;
            }

        }
        
        let text = arWordForms[f-1];
        return text;  
    },

    basketItemHash(id,params) {
        let str = ""+id+"|";
        params.forEach((p) => str += p.CODE+"="+p.VALUE+"|");
        return str
    },

    /**
     * конвертирет цвет 3-х буквенный в 6-ти
     */
    convertHexColor(value) {
        if (value.length === 4) {
            value = "#" + value.substring(1, 4).split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }

        return value;
    },

    /**
     * Настройки маски ввода E-Mail
     */
    maskEmail() {
        return {
            mask: "*{1,64}[.*{1,64}][.*{1,64}][.*{1,64}]@*{1,64}.*{1,64}[.*{2,64}][.*{2,6}][.*{1,2}]",
            greedy: false,
            onBeforePaste: function(pastedValue) {
                pastedValue = pastedValue.toLowerCase();
                return pastedValue.replace("mailto:", "");
            },
            definitions: {
                "*": {
                        validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\\-]",
                        cardinality: 1,
                        casing: "lower"
                }
            },
            onUnMask: function(maskedValue) {
                return maskedValue;
            }
        }
    },

    /**
     * Настройки маски ввода телефона
     */
    maskPhone() {
        return { 
            mask: '8(999)999-99-99' 
        };
    },

    /**
     * Экранирует HTML
     */
    escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    },


     /**
     * Обработка готовности Fancybox
     */
    fancyBoxOnReady(fancybox) {

        //устанавилваем правильную позицию тулбара
        let safeArea = store.getters.getSafeArea;
        if(safeArea && safeArea.top > 0 ) {
            let toolbar = fancybox.$container.querySelector('.fancybox__toolbar');
            toolbar.style.top = safeArea.top+"px";
        }

        //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
        window.history.pushState({}, "", window.location.href + "?photoOpen");
        //добавляем закрытие в стэк обработчиков возврата назад
        store.commit("addToBackStack", this.facyBoxOnHistoryBack);
        this.fancybox = fancybox;
        this.fancyboxWatchClose = true;
    },

    /**
     * Обработка закрытия Fancybox
     */
    fancyBoxOnClose() {
        if (this.fancyboxWatchClose) {
            history.back();
            return;
        }
        //удаляем закрытие из стэка обработчиков возврата назад
        store.commit("removeFromBackStack", this.facyBoxOnHistoryBack);
    },

    /**
     * Обработка нажатия назад в браузере
     */
    facyBoxOnHistoryBack() {
        tools.fancyboxWatchClose = false;
        tools.fancybox.close();
        //удаляем закрытие из стэка обработчиков возврата назад
        store.commit("removeFromBackStack", tools.facyBoxOnHistoryBack);
    },

    /**
     * Возвращает параметры для ресайза картинок товара в списках
     */
    getProductListResizeOptions() {
        return {
            PREVIEW_PICTURE: {
                WIDTH: 400,
                HEIGHT: 300,
                TYPE: "PROPORTIONAL",
            },
            DETAIL_PICTURE: {
                WIDTH: 400,
                HEIGHT: 300,
                TYPE: "PROPORTIONAL",
            },
            LANDING_PHOTOS: {
                WIDTH: 400,
                HEIGHT: 300,
                TYPE: "PROPORTIONAL",
            },
        };
    },

    /**
     * Возвращает параметры для ресайза картинок товара в карточке просмотра
     */
    getProductDetailResizeOptions() {
        return {
            PREVIEW_PICTURE: {
                WIDTH: 800,
                HEIGHT: 600,
                TYPE: "PROPORTIONAL",
            },
            DETAIL_PICTURE: {
                WIDTH: 800,
                HEIGHT: 600,
                TYPE: "PROPORTIONAL",
            },
            MORE_PHOTO: {
                WIDTH: 800,
                HEIGHT: 600,
                TYPE: "PROPORTIONAL",
            },
            LANDING_PHOTOS: {
                WIDTH: 800,
                HEIGHT: 600,
                TYPE: "PROPORTIONAL",
            },
        };
    },

    /**
     * Подгружает список изображений
     */
    preloadImages(images) {

        //создаём прамис, чтобы можно было подписаться на окончание загрузки всех фото
        return new Promise(resolve => {

            let loadCnt = 0;

            images.forEach((src) => {
                var tempImage = new Image();
                tempImage.src = src;
                tempImage.addEventListener("load", () => { 
                    loadCnt +=1;
                    if(loadCnt == images.length) {
                        resolve();
                    }
                }, true);
            });

            if(images.length == 0) {
                resolve();
            }

        });
 
    },

    /**
     * Получает значение CSS переменной
     */
    getCSSVariable(name) {
        let root = document.documentElement;
        var styles = window.getComputedStyle(root);
        var value = styles.getPropertyValue(name);
        value = value.trim();
        return value;
    }

}

export default tools;