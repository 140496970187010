<template>
    <okraina-view :class="'okraina-v-news-detail'" @refresh="refresh" ref="view" :hideMenu="true"
        :contentHorizontalPadding="0">

        <template v-slot:header>
            <h1>
                Новости
            </h1>
        </template>


        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="!loading && canShow">

                <template v-if="!news">

                    <okraina-not-found caption="Новость не найдена" />

                </template>

                <template v-else>

                    <div class="image preloaded" v-if="news.FIELDS.PREVIEW_PICTURE"
                        v-lazy:background-image="news.FIELDS.PREVIEW_PICTURE.SRC">
                        <div class="preload"
                            :style="{ backgroundImage: 'url(\'' + news.FIELDS.PREVIEW_PICTURE.PRELOAD + '\')' }">
                        </div>
                        <div class="loader">
                            <okraina-loader-inline />
                        </div>
                    </div>

                    <div class="inner">

                        <div class="date">
                            {{ dateFormated }}
                        </div>
                        <div class="name" v-html="news.FIELDS.NAME"></div>
                        <div class="text" v-html="news.FIELDS.DETAIL_TEXT" @click="onTextClick"></div>
                    </div>

                </template>

            </template>


        </template>

    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaNotFound from "@/components/not.found.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import moment from "moment";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/news.detail.js";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-news-detail",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaLoaderInline,
        okrainaNotFound
    },
    data() {
        return {
            loading: true,
            news: false,
            canShow: false
        };
    },
    props: {
        code: {
            type: String,
            default() {
                return "";
            },
        },
        id: {
            type: [String, Number],
            default() {
                return "";
            },
        },
    },
    computed: {
        ...mapGetters({
            server: "getServer",
        }),
        dateFormated() {
            if (this.news) {
                return moment(this.news.FIELDS.ACTIVE_FROM, 'DD.MM.YYYY').format("DD MMMM YYYY");
            }
            return ""
        },
    },
    watch: {
        code: function () {
            this.loadData();
        },
        id: function () {
            this.loadData();
        },
        news: function () {
            this.setMeta();
        }
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData(resolve = false) {
            this.loading = resolve ? false : true;

            let method = this.id > 0 ? "getbyid" : "getbycode";

            rest
                .call("news." + method, {
                    method: "post",
                    data: {
                        ID: this.id,
                        CODE: this.code,
                        RESIZE_OPTIONS: {
                            WIDTH: 900,
                            HEIGHT: 330,
                            TYPE: "PROPORTIONAL",
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.news = data;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                }).finally(() => {
                    this.loading = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.loadData(resolve);
        },

        /**
         * Устанавливает мета данные страницы
         */
        setMeta() {
            if (this.news) {
                meta.write(metaTemplates, this.news.META, this.news);
            }
        },

        /**
         * Клик в тексте новости
         */
        onTextClick(e) {
            //если клик по ссылке
            if (e.target.tagName == "A") {

                let url = e.target.getAttribute('href');
                //получим относительную ссылку
                url = url.replace("http://" + this.server, "").replace("https://" + this.server, "");

                this.routeClick(e, url);

            }

        },

        /**
         * Маршрутизация клика по ссылке
         */
        routeClick(e, url) {

            let query = this.getUrlQuery(url);

            //куда-то в каталог
            if (url.startsWith("/catalog")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }
            //в акции
            else if (url.startsWith("/clients/actions")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }
            //в новости
            else if (url.startsWith("/about/news")) {
                this.$router.push({ path: url, query: query });
                e.preventDefault();
            }
        },

        /**
         * Получает параметры из URL
         */
        getUrlQuery(url) {
            //парсим параметры (например переход к странице поиска)
            const [, paramString] = url.split('?');
            let urlParams = new URLSearchParams(paramString);
            let entries = urlParams.entries();
            let query = {};
            for (let [key, value] of entries) {
                query[key] = value;
            }

            return query;
        }

    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setMeta();
        });
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },
}

</script>

<style lang="scss">
.okraina-v-news-detail {

    .image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-bottom: 65.66%;
        padding-top: 0;
        height: 0;
    }

    .inner {
        padding: 24px 16px 14px 16px;
        background-color: var(--color-block-background);
    }

    .date {
        color: var(--color-text-description);
        font-size: 12px;
    }

    .name {
        margin-top: 10px;

        font-weight: 600;
        font-size: 15px;
        color: var(--color-text-title);
    }

    .text {
        margin-top: 15px;
        color: var(--color-text-primary);

        * {
            text-indent: 0px !important;

        }

        iframe {
            width: 100%;
            height: auto;
        }

        img {
            height: auto;
        }

        h1 {
            width: auto !important;
        }
    }
}
</style>