<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="92">

        <template v-slot:body>

            <div class="okraina-cf">

                <template v-if="loading">
                    <div class="text-center">
                        <okraina-loader-inline />
                    </div>
                </template>

                <template v-else>

                    <transition>
                        <okraina-overlay v-show="ajax" />
                    </transition>

                    <div class="items">
                        <div class="item" v-for="item in items" :key="item.ID">

                            <template v-if="isSwitcher(item)">

                                <div class="switcher" :class="{ disabled: value.DISABLED }" v-for="value in item.VALUES"
                                    :key="value.CONTROL_ID">
                                    <div>
                                        <div class="name">{{ item.NAME }}</div>
                                    </div>
                                    <div>
                                        <okraina-control-switch label="" v-model="values[value.CONTROL_ID]"
                                            :value="value.HTML_VALUE" :right="true" :disabled="value.DISABLED" />
                                    </div>

                                </div>
                            </template>

                            <template v-else>

                                <div class="common">
                                    <div class="name">{{ item.NAME }}</div>
                                    <div class="values">
                                        <div class="value" v-for="value in item.VALUES" :key="value.CONTROL_ID"
                                            :class="{ selected: value.CHECKED, disabled: value.DISABLED }"
                                            @click="onValueClick(value)">
                                            {{ value.VALUE }}

                                            <span v-if="value.CHECKED" class="icon fi fi-delete"></span>
                                        </div>
                                    </div>
                                </div>
                            </template>

                        </div>

                        <div class="item" v-if="price">
                            <div class="price">
                                <div class="name">{{ price.CODE == 'BASE' ? 'Цена' : price.NAME }}</div>
                                <div class="controls">
                                    <div>
                                        <okraina-control-input type="number" label=""
                                            :placeholder="Math.floor(price.VALUES.MIN.VALUE)"
                                            v-model="values[price.VALUES.MIN.CONTROL_NAME]">
                                            <template v-slot:left>
                                                <div class="inner-label">от</div>

                                            </template>
                                        </okraina-control-input>
                                    </div>
                                    <div>
                                        <okraina-control-input type="number" label=""
                                            :placeholder="Math.ceil(price.VALUES.MAX.VALUE)"
                                            v-model="values[price.VALUES.MAX.CONTROL_NAME]">
                                            <template v-slot:left>
                                                <div class="inner-label">до</div>
                                            </template>
                                        </okraina-control-input>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="btn btn-block btn3" v-if="Object.keys(filter).length > 0" @click="clear">Сбросить</div>

                    <okraina-fixed-panel v-if="count > 0" :modal="true">

                        <div class="btn btn-block btn1" v-ripple @click="apply">
                            Показать {{ count }} {{ countStr }}

                        </div>

                    </okraina-fixed-panel>

                </template>

            </div>

        </template>

    </okraina-sheet>
</template>


<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue"
import okrainaFixedPanel from '@/components/fixed.panel.vue'
import okrainaControlSwitch from '@/components/controls/switch.vue'
import okrainaOverlay from "@/components/overlay.vue";
import okrainaControlInput from "@/components/controls/input.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

export default {
    name: "okraina-catalog-filter",
    components: {
        okrainaSheet,
        okrainaLoaderInline,
        okrainaFixedPanel,
        okrainaControlSwitch,
        okrainaOverlay,
        okrainaControlInput
    },
    data() {
        return {
            visibleInternal: this.visible,
            filter: this.modelValue,
            loading: false,
            ajax: false,
            loaded: false,
            response: {},

            values: {}
        };
    },
    emits: ["update:modelValue", "update:visible"],
    props: {
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        modelValue: {
            type: [Object],
            default() {
                return {};
            },
        },
        preFilter: {
            type: [Object],
            default() {
                return {};
            },
        },
        sectionId: {
            type: [Number, String],
            default() {
                return "";
            },
        }
    },
    computed: {

        /**
         * Подготовленный список св-в фильтра
         */
        items() {
            if (!this.response || !this.response.ITEMS) {
                return [];
            }

            let items = [];

            for (var id in this.response.ITEMS) {
                let item = this.response.ITEMS[id];
                //цену не включаем
                if (item.PRICE) {
                    continue;
                }
                //если нет значений
                if (!item.VALUES || item.VALUES.length == 0) {
                    continue;
                }

                //если вид отображения не "Флаги"
                if (item.DISPLAY_TYPE != 'F') {
                    continue;
                }

                items.push(item);
            }

            //отсортируем по типу контрола для отображения
            items.sort((a, b) => {
                let aSort = 10;
                let bSort = 10;
                if (this.isSwitcher(a)) {
                    aSort = 1
                }

                if (this.isSwitcher(b)) {
                    bSort = 1
                }

                if (aSort > bSort) {
                    return 1;
                } else if (aSort < bSort) {
                    return -1;
                } else {
                    return 0;
                }

            })

            return items;
        },

        /**
         * Цена для фильтра
         */
        price() {
            if (!this.response || !this.response.ITEMS) {
                return false;
            }

            let price = false;
            for (var id in this.response.ITEMS) {
                let item = this.response.ITEMS[id];
                if (item.PRICE) {
                    price = item;
                    break;
                }
            }

            //если данных о мин/мак нету или они равны
            if (!price.VALUES || !price.VALUES.MIN || !price.VALUES.MAX || price.VALUES.MIN.VALUE == price.VALUES.MAX.VALUE) {
                return false;
            }

            return price;
        },

        count() {

            if (!this.response || !this.response.ELEMENT_COUNT) {
                return 0;
            }

            return parseInt(this.response.ELEMENT_COUNT);

        },

        countStr() {
            let str = tools.countWord(this.count, ['товар', 'товара', 'товаров']);

            return str;
        },

    },
    watch: {

        visibleInternal: function (newVal) {
            this.$emit("update:visible", newVal);
        },
        visible: function () {
            this.visibleInternal = this.visible;
            if (this.visible) {
                this.onOpen();
            }
        },

        modelValue: function (newVal) {
            this.filter = newVal;
        },

        filter: function () {
            this.$emit("update:modelValue", this.filter);
        },

        values: {
            handler() {
                this.ajax = true;
                this.loadData();
            },
            deep: true
        },

        preFilter: function () {
            //сбрасываем значения и перезагружаем тем самым фильтр
            this.values = {}
            this.filter = {};
        }

    },

    methods: {

        onOpen() {

            if (!this.loaded) {

                this.loadData();
            }

        },

        /**
         * Подгружает данные
         */
        loadData() {

            this.loading = !this.loaded;

            let data = Object.assign({
                SECTION_ID: this.sectionId,
                PREFILTER: this.preFilter,
                ajax: this.ajax ? "y" : ""
            }, this.values);

            //запрашивает даные
            rest
                .call("catalog.products.filter", {
                    method: "post",
                    data: data,
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.response = data;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                    this.ajax = false;
                });
        },

        /**
         * Кли по значению
         */
        onValueClick(value) {

            if (value.DISABLED) {
                return;
            }

            if (this.values[value.CONTROL_ID]) {
                delete this.values[value.CONTROL_ID];
            } else {
                this.values[value.CONTROL_ID] = value.HTML_VALUE;
            }


        },

        /**
         * Проверяет нужно ли отобаржать св-во как свитчер
         */
        isSwitcher(item) {
            let codes = ["NEWPRODUCT", "SALELEADER", "ACTION", "AVAILABLE", "HAS_RIFLING"];

            return codes.includes(item.CODE);
        },


        /**
         * Применить
         */
        apply() {

            this.filter = this.response.GLOBAL_FILTER;
            this.visibleInternal = false;
        },

        /**
         * Сбросить
         */
        clear() {

            this.filter = {};
            this.values = {};
            this.visibleInternal = false;
        }

    },

    created() {

    },
};
</script>

<style lang="scss">
.okraina-cf {

    padding-bottom: 50px;

    .item {
        padding: 15px 0px;
        border-top: 1px solid rgba(var(--color-text-primary-rgb), 0.1);

        .name {
            font-weight: 500;
            font-size: 15px;
            color: var(--color-text-dark);
        }

        .switcher {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 15px;
            align-items: center;

            &.disabled {
                opacity: 0.5;
            }
        }

        .common {
            .name {
                margin-bottom: 10px;
            }

            .value {
                position: relative;
                display: inline-block;
                border-radius: 24px;
                font-weight: 500;
                color: var(--color-text-dark);
                line-height: 1;
                padding: 9px 12px;
                background-color: var(--color-color9);
                margin-right: 8px;
                margin-bottom: 8px;

                &.selected {
                    background-color: var(--color-btn1-background);
                    color: var(--color-btn1-text);
                    padding-right: 25px;
                }

                &.disabled {
                    opacity: 0.5;
                }

                .icon {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                }
            }


        }

        .price {
            .name {
                margin-bottom: 10px;
            }

            .controls {
                display: grid;
                grid-template-columns: 0.5fr 0.5fr;
                column-gap: 15px;
            }
        }




    }
}
</style>
