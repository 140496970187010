<template>
    <div class="okraina-register">

        <!--Индикатор загрузки-->
        <transition>
        <okraina-loader-page v-show="loading" />
        </transition>

        <!-- Ошибка загрузки -->
        <template v-if="loadingError || loading">
        <div class="text-center">
            Ошибка загрузки данных.
            <br /><br />
            <div class="btn btn1" @click="retryLoad">Повторить</div>
        </div>
        </template>

        <template v-if="!loading || !loadingError">
        <h2>Личные данные</h2>
        <template v-for="field in fieldsCommon" :key="field.CODE">
        <div class="field"
            :class="{ 'form-group': field.TYPE == 'radio', 'form-group-error': errors[field.CODE] }">

            <div class="label" v-if="field.TYPE == 'radio'">
                {{ field.NAME }}
                <span v-if="field.REQUIED == 'Y'">*</span>
            </div>

            <!--Текстовое поле-->
            <template v-if="field.TYPE == 'text'">
                <okraina-control-input :label="field.NAME" :required="field.REQUIED == 'Y'" :type="'text'"
                    v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                    v-model:valid="valids[field.CODE]" :mask="getFieldMask(field)" />
            
            </template>
        
            <!--Дата-->
            <template v-if="field.TYPE == 'date'">
                <okraina-control-input :label="field.NAME" :required="field.REQUIED == 'Y'" :type="'date'"
                    v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                    v-model:valid="valids[field.CODE]" />
            
            </template>
        
            <!-- Radio-переключатель -->
            <template v-if="field.TYPE == 'radio'">
                <div class="values">
                    <div class="value" v-for="value in field.VALUES" :key="value.ID">
                        <okraina-control-radio :label="value.NAME" :required="field.REQUIED == 'Y'"
                            v-model="values[field.CODE]" v-model:error="errors[field.CODE]"
                            v-model:valid="valids[field.CODE]" :value="value.ID" />
                    </div>
                </div>
            </template>

        </div>
        </template>

        <template v-if="fieldsPassword.length > 0">
        <h2>Задать пароль</h2>
        <template v-for="field in fieldsPassword" :key="field.CODE">
            <!--Пароль-->
            <template v-if="field.TYPE == 'password'">
                <okraina-control-input :label="field.NAME" :required="field.REQUIED == 'Y'"
                    :type="showPassword[field.CODE] ? 'text' : 'password'" v-model="values[field.CODE]"
                    v-model:error="errors[field.CODE]" v-model:valid="valids[field.CODE]"
                    :description="field.CODE == 'USER_CONFIRM_PASSWORD' ? this.passwordRequirements : ''">
                        
                    <template v-slot:right>
                        <div v-if="!showPassword[field.CODE]" class="action"
                            @click="onShowPasswordClick(field.CODE)">
                            <span class="icon fi fi-eye"></span>
                        </div>
                        <div v-else class="action" @click="onHidePasswordClick(field.CODE)">
                            <span class="icon fi fi-eye-close"></span>
                        </div>
                    </template>
                
                </okraina-control-input>
            </template>
        </template>
        </template>

        <template v-if="useCaptcha">
        <h2>Защита регистрации</h2>
        <okraina-control-input v-model="captchaWord" type="text" label="Слово на картинке"
            :captcha="captchaURL" :required="true" v-model:error="captchaError"
            v-model:valid="captchaValid" />
        </template>

        <div class="btn-wrap">
        <div class="btn btn-block btn1" :class="{ 'wait': saving }" v-ripple="'rgba(255, 255, 255, 0.15)'"
            @click="register">
            Зарегистрироваться
            <div v-if="saving" class="btn-loader"></div>
        </div>
        </div>

        </template>

    </div>

</template>


<script>
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaControlRadio from "@/components/controls/radio.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
    name: "okraina-register",
    components: {
        okrainaControlInput,
        okrainaControlRadio,
        okrainaLoaderPage,
    },
    emits: ["onRegister"],
    data() {
        return {
            loading: false,
            loadingError: false,
            saving: false,
            showPassword: {},
            needCaptcha: false,
            fields: [],
            login: "",
            values: {},
            errors: {},
            valids: {},

            captchaWord: "",
            captchaSid: "",
            captchaURL: "",
            captchaValid: false,
            captchaError: false,

            emailConfirmation: false,
            userAgreement: "",
            passwordRequirements: "",
            useCaptcha: false,

            bonus: 0,
            canShow: false

        };
    },
    computed: {
        
        ...mapGetters({
            isAuthorized: "isAuthorized",
        }),

        fieldsCommon() {
            return this.fields.filter(f => f.CODE != 'USER_PASSWORD' && f.CODE != 'USER_CONFIRM_PASSWORD')
        },
        fieldsPassword() {
            return this.fields.filter(f => f.CODE == 'USER_PASSWORD' || f.CODE == 'USER_CONFIRM_PASSWORD')
        },

    },
    methods: {

        /**
        * Загружает данные
        */
        loadData() {

            if (this.loading) {
                return;
            }

            this.loading = true;

            rest
                .call("auth.register.info", {
                    method: "post",
                    data: {
                    },
                })
                .then((data) => {
                    this.loadingError = !data.SUCCESS;
                    if (data.SUCCESS) {
                    
                        this.fields = data.FIELDS;
                                        
                        this.passwordRequirements = data.PASSWORD_REQUIREMENTS;
                        this.emailConfirmation = data.EMAIL_CONFIRMATION == "Y";
                        this.userAgreement = data.USER_AGREEMENT;
                        this.useCaptcha = data.USE_CAPTCHA == "Y";
                        this.captchaSid = data.CAPTCHA_SID ? data.CAPTCHA_SID : "";
                        this.captchaURL = data.CAPTCHA_URL ? data.CAPTCHA_URL : "";
                    
                        this.resetValues();
                        this.restoreData();
                    
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        
        },

        /**
        * Повторная попытка загрузки
        */
        retryLoad() {
            this.loadData();
        },

        /**
        * Регистрация
        */
        register() {
            if (this.saving) {
                return;
            }

            //првоерка введённых данных
            if (!this.checkValues()) {
                return;
            }
            this.saving = true;

            let data = Object.assign({
                CAPTCHA_WORD: this.captchaWord,
                CAPTCHA_SID: this.captchaSid
            }, this.values);

            rest
                .call("auth.register", {
                    method: "post",
                    data: data,
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        //переход к авторизации
                        this.$emit("onRegister", this.values.USER_LOGIN,data.BONUS);

                    } else {

                        //если требуется капча
                        if (this.useCaptcha) {
                            this.captchaSid = data.CAPTCHA_SID;
                            this.captchaURL = data.CAPTCHA_URL;
                            this.captchaWord = "";
                        }

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.saving = false;
                });

        },

        /**
        * Сброс полей
        */
        resetValues() {
            this.values = {};
            
            this.fields.forEach(f => {
            
                this.values[f.CODE] = "";
                this.valids[f.CODE] = false;
                this.errors[f.CODE] = false;
            
            });
        },

        /**
        * Проверяет значения
        */
        checkValues(showErrors = true) {
            
            let error = false;

            this.fields.forEach(f => {

                error = !this.valids[f.CODE];
                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors[f.CODE] = error;
                }
            });

            if (this.useCaptcha) {
                error = !this.captchaValid;
                if (showErrors || !error) {
                    this.captchaError = error;
                }
            }

            for (var key in this.errors) {
                if (this.errors[key]) {
                    return false
                }
            }
            if (this.captchaError) {
                return false;
            }

            return true;
        },

        /**
        * Получает маску для поля
        */
        getFieldMask(field) {
            if (field.CODE == "EMAIL" || field.CODE == "USER_EMAIL") {
                return tools.maskEmail();
            } else if (field.CODE == "PERSONAL_PHONE" || field.CODE == "USER_LOGIN") {
                return tools.maskPhone();
            }
            return null;
        },
        /**
         * Клик по иконке "показать пароль"
         */
        onShowPasswordClick(code) {
            this.showPassword[code] = true;
        },

        /**
         * Клик по иконке "скрыть пароль"
         */
        onHidePasswordClick(code) {
            this.showPassword[code] = false;
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            if (this.isAuthorized) {
                return false;
            }
            return {
                "component": this.$options.name,
                "data": {
                    values: this.values
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }

    },
    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормозов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);

        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },
};
</script>

<style lang="scss">
    .okraina-register{
        .btn-wrap {
            margin-top: 25px;
            margin-bottom: 20px;
        }
    }  
</style>