<template>
  <okraina-view :class="'okraina-v-order-pay'" :refreshDisabled="true" :hideMenu="true">

    <template v-slot:header>
      <h1 class="title">
        Оплата заказа
      </h1>
    </template>

    <template v-slot:content>

      <!--Индикатор загрузки-->
      <transition>
        <okraina-loader-page v-show="loading" />
      </transition>

      <template v-if="!loading">

        <template v-if="!order">
          <okraina-not-found caption="Заказ не найден" />
        </template>
        <template v-else>

          <div v-if="order.FIELDS.CANCELED == 'Y'" class="canceled">
            <okraina-illustration :picture="imageError" caption="Заказ отменён. <br/>Оплата невозможна." />
          </div>
          <div v-else-if="order.FIELDS.PAYED == 'Y'" class="success">
            <okraina-illustration :picture="imageSuccess" caption="Оплата успешно произведена." />
          </div>
          <div v-else-if="order.DATA.CAN_REPAY != 'Y'">
            <okraina-illustration :picture="imageError" caption="Запрет оплаты." :details="order.DATA.NOTICE" />
          </div>
          <div v-else-if="fail" class="fail">
            <okraina-illustration :picture="imageError" caption="Произошла ошибка при оплате заказа.">
              Если у Вас возникли проблемы с оплатой, Вы можете обратиться к нашему менеджеру
              <br />
              <a class="region btn btn3 btn-block slim" :href="'tel:' + region.PROPS.PHONE">Позвонить </a>

              <template v-if="order.DATA.CAN_CHANGE_PAYMENT == 'Y'">
                <br />
                Или Вы можете поменять <span class="a" @click="goToOrderDetail">способ оплаты</span>
              </template>

            </okraina-illustration>
          </div>
          <div v-else-if="waitPayment" class="nspk">
            <okraina-illustration :picture="imageTimer" caption="Ожидаем оплату ..." />
          </div>

          <!-- Для Веб -->
          <template v-else-if="!isNative">

            <div v-if="paymentLoading" class="text-center">
              <okraina-loader-inline />
            </div>
            <div v-else v-html="paymentHTML" class="payment text-center" @click="onPaymentHtmlClick"></div>

          </template>

        </template>

      </template>

    </template>

    <template v-slot:footer v-if="fail || waitPayment">
      <div class="btns-wrap">
        <template v-if="fail">
          <div v-if="fail || waitPayment" class="btn btn1 btn-block btn-retry" @click="retry">Повторить</div>
        </template>
        <template v-else>
          <div v-if="waitPayment" class="btn btn3 btn-block btn-check" @click="checkPaymentByTimer" v-ripple>
            Проверить</div>
          <div v-if="fail || waitPayment" class="btn btn1 btn-block btn-retry" @click="retry">Повторить</div>
        </template>

      </div>
    </template>

  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaNotFound from "@/components/not.found.vue";
import okrainaIllustration from "@/components/illustration.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import axios from 'axios';
import tools from "@/plugins/tools";

import imageErrorPath from "@/assets/images/error.svg";
import imageSuccessPath from "@/assets/images/success.svg";
import imageTimerPath from "@/assets/images/timer.svg";

import { mapGetters } from "vuex";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { AppLauncher } from "@capacitor/app-launcher";
import { createWidget } from '@sber-ecom-core/sberpay-widget';

export default {
  name: "okraina-v-order-pay",
  components: {
    okrainaView,
    okrainaLoaderPage,
    okrainaLoaderInline,
    okrainaNotFound,
    okrainaIllustration
  },
  data() {
    return {
      loading: false,
      order: false,
      paymentLoading: false,
      paymentHTML: "",
      fail: false,
      needCheck: false,
      autoOpen: true,

      waitPayment: false,
      checkPaymentTimer: 0,
      checkPaymentLoading: false,
      timeoutTimer: false,

      subscriptionBeforeLoad: false,
      subscriptionLoadStart: false,
      subscriptionLoadStop: false,
      subscriptionExit: false,
      subscriptionMessage: false,
      paymentBrowser: false,
      resultBrowser: false,

      imageError: imageErrorPath,
      imageSuccess: imageSuccessPath,
      imageTimer: imageTimerPath
    };
  },
  props: {
    id: {
      type: String,
      default() {
        return "";
      },
    },
  },
  computed: {
    ...mapGetters({
      isNative: "isNativePlatform",
      settings: "getSiteSettings",
      platform: "getPlatform",
      regions: "getRegions",
      connection: "getConnection"
    }),

    paymentLink() {
      return "https://" + this.$store.getters.getServer + "/rest/pages/order/payment/?ORDER_ID=" + this.id + "&apptoken=" + this.$store.getters.getAccessToken;
    },

    sucсessRedirects() {
      return this.settings.ORDER_PAYMENT_SUCCESS_REDIRECT;
    },

    failRedirects() {
      return this.settings.ORDER_PAYMENT_FAIL_REDIRECT;
    },

    receiveRedirects() {
      return this.settings.ORDER_PAYMENT_RECEIVE_REDIRECT;
    },

    /**
     * Флаг оплаты заказа
     */
    payed() {
      if (!this.order) {
        return false;
      }

      return this.order.FIELDS.PAYED == 'Y';
    },

    region() {
      let item = this.regions.find(r => r.PROPS.DOMAIN == this.connection.server);
      return item;
    }

  },
  watch: {
    payed: function (newVal) {
      if (newVal) {
        bus.emit("OKRAINA_ORDER_E_PAYED", { id: this.id });
      }
    },
  },

  methods: {

    /**
     * Назад
     */
    back() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push("/");
      }
    },

    /**
     * Загрузка данных 
     */
    loadData() {

      this.loading = true;

      //поулчаем заказ
      rest
        .call("order.getbyid", {
          method: "post",
          data: {
            ID: this.id,
          },
        })
        .then((data) => {
          //если ошибка входа
          if (data.SUCCESS) {
            this.onSuccessLoad(data);
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Обработка успешной загрузки
     */
    onSuccessLoad(data) {
      this.order = data;

      if (this.order.FIELDS.PAYED == 'Y') {
        if (this.fail) {
          this.fail = false;
        }
        return;
      }

      if (this.order.DATA.CAN_REPAY != 'Y') {
        return;
      }

      //если не нативная платформа
      if (!this.isNative) {
        //загрузим HTML для оплаты
        this.loadPymentInline();
      }
      //иначе открываем через отдельный инстанс браузера
      else {
        if (this.autoOpen) {
          this.openPayment();
        }
      }
    },

    /**
     * Открытие оплаты
     */
    openPayment() {
      if (!this.order.PAY_SYSTEM) {
        this.fail = true;
        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: 'В заказе не указан способ оплаты. Обратитесь к менеджеру интернет-магазина.', timeout: 10000 });
        return;
      }

      if (this.order.PAY_SYSTEM.ACTION_FILE == 'yandexcheckout') {
        this.openYoomoneySDK();
      } else if (this.order.PAY_SYSTEM.ACTION_FILE == 'sberpay') {
        this.openSberPaySDK();

      } else {
        this.openPaymentView();
      }
    },

    /**
     * Загружает HTML для оплаты
     */
    loadPymentInline() {

      this.paymentLoading = true;

      axios({
        url: this.paymentLink + "&inline=yes",
        timeout: 15000,
        method: "GET"
      }).then((response) => {
        this.paymentHTML = response.data;
      })
        .finally(() => {
          this.paymentLoading = false;
        });
    },

    /**
     * Открывает ссылку на оплату
     */
    openPaymentView(url = "") {
      this.fail = false;
      this.needCheck = true;

      let color = tools.getCSSVariable('--color-background');
      url = url ? url : this.paymentLink;
      this.paymentBrowser = InAppBrowser.create(url, "_blank", {
        location: "no",
        toolbarcolor: tools.convertHexColor(color),
        toolbarposition: "top",
        hidenavigationbuttons: "yes",
        closebuttoncaption: "Закрыть",
        closebuttoncolor: "#FFFFFF"
      });

      //подписываемся на загрузку URL
      this.paymentBrowser.on("loadstart").subscribe((event) => {
        this.onLoadStart(event);
      });

      //подписываемся на окончание загрузки URL
      this.paymentBrowser.on("loadstop").subscribe(() => {
        this.onLoadStop(this.paymentBrowser);
      });

      //подписываемся на закрытие браузера
      this.paymentBrowser.on("exit").subscribe(() => {
        this.unsubscribe();
        this.checkPayment();
      });

      //запускаем проверку оплаты по таймеру
      this.startCheckPaymentByTimer();

    },

    /**
     * Обработка начала загурзки нового URL в inAppBrowser
     */
    onLoadStart(event) {

      //смотрим может это возврат покупателя на страницу успешной оплаты
      let redirect = this.sucсessRedirects.find((r) => event.url.includes(r.SOURCE_URL));
      if (redirect) {
        this.onSuccessPayment();
        return;
      }

      //смотрим может это возврат покупателя на страницу ошибки оплаты
      redirect = this.failRedirects.find((r) => event.url.includes(r.SOURCE_URL));
      if (redirect) {
        this.onFailPayment();
        return;
      }

      //смотрим может это возврат покупателя на страницу обработкит оплаты
      redirect = this.receiveRedirects.find((r) => event.url.includes(r.SOURCE_URL));
      if (redirect) {
        this.onPaymentRedirect(event.url, redirect);
        return;
      }

      //может это попытка открыть приложение для оплаты по СБП / Androd
      if (event.url.includes("intent://qr.nspk.ru/")) {

        //ссылка должна быть вида:
        //https://qr.nspk.ru/AD10000BNDDFF9U99OOAU3DKRN42V5GQ?type=02&bank=100000000004&sum=18100&cur=RUB&crc=6CBD
        //let url = event.url.replace("http://intent", "https");
        //url = url.replace(/#intent.*end/ig, "");

        let test = event.url.match(/scheme=(.*);/);
        if (test && test.length > 1) {
          let schema = test[1];
          let url = event.url.replace("http://intent", schema);
          url = url.replace(/#intent.*end/ig, "");
          this.openPaymentApp(url);
        }

      }
      //может это попытка открыть приложение для оплаты по СБП / ios
      else if (event.url.includes("qr.nspk.ru") && event.url.startsWith("bank")) {
        this.openPaymentApp(event.url);
      }
      //в последнее время СБП возвращает ссылки для ios уже другие, похожие на ios
      else if (event.url.includes("qr.nspk.ru") && event.url.startsWith("http://bank")) {
        let url = event.url.replace("http://", "");
        this.openPaymentApp(url);

      }
      //попытка открыть Альфа Пей
      else if (event.url.includes("alfa-mobile.alfabank.ru/mobile")) {
        let url = event.url;
        this.openPaymentApp(url);
      }
      //попытка открыть Альфа Пей
      else if (event.url.includes("mirpay://")) {
        let url = event.url.replace("http://", "");
        this.openPaymentApp(url);
      }

    },

    /**
     * Обработка окончания загрузки страницы
     */
    onLoadStop(browser) {

      //внедяем объект для взаимодействия
      browser.executeScript({
        code: "" +
          "window.MobileApp = {" +
          "   goToScreen: function(screen) {" +
          "        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({action:\"GO_TO_SCREEN\",screen: screen}));" +
          "   }" +
          "};"

      });

    },

    /**
     * Удаляет подписки на события inAppBrowser
     */
    unsubscribe() {

      if (this.subscriptionBeforeLoad) {
        this.subscriptionBeforeLoad.unsubscribe();
        this.subscriptionBeforeLoad = false;
      }
      if (this.subscriptionLoadStart) {
        this.subscriptionLoadStart.unsubscribe();
        this.subscriptionLoadStart = false;
      }
      if (this.subscriptionExit) {
        this.subscriptionExit.unsubscribe();
        this.subscriptionExit = false;
      }
      if (this.subscriptionLoadStop) {
        this.subscriptionLoadStop.unsubscribe();
        this.subscriptionLoadStop = false;
      }
      if (this.subscriptionMessage) {
        this.subscriptionMessage.unsubscribe();
        this.subscriptionMessage = false;
      }

    },

    /**
     * Действие при успешной оплате
     */
    onSuccessPayment() {
      this.needCheck = false;
      if (this.paymentBrowser) {
        this.paymentBrowser.close();
      }

      this.order.FIELDS.PAYED = 'Y';

      this.stopCheckPaymentByTimer();
    },

    /**
     * Дейтсвие при ошибке оплаты
     */
    onFailPayment() {
      this.needCheck = false;
      if (this.paymentBrowser) {
        this.paymentBrowser.close();
      }
      this.fail = true;
      this.stopCheckPaymentByTimer();
    },

    /**
     * Обработка возврата на страницу успешной оплаты
     */
    onPaymentRedirect(url, redirect) {

      this.needCheck = false;
      if (this.paymentBrowser) {
        this.paymentBrowser.close();
      }

      setTimeout(() => {
        url = url.replace(redirect.SOURCE_URL, redirect.REDIRECT_URL);
        this.openResultBrowser(url);
        this.needCheck = true;
      }, 600);

    },


    /**
     * Открывает ссылку на обработку оплаты
     */
    openResultBrowser(url) {
      this.resultBrowser = InAppBrowser.create(url, "_blank");

      //подписываемся на окончание загрузки URL
      this.resultBrowser.on("loadstop").subscribe(() => {
        this.onLoadStop(this.resultBrowser);
      });

      //подписываемся на закрытие браузера
      this.resultBrowser.on("exit").subscribe(() => {
        this.unsubscribe();
        this.stopCheckPaymentByTimer();
        this.checkPayment();
      });

      //подписываемся на сообщение браузера
      this.resultBrowser.on("message").subscribe((event) => {
        this.onBrowserMessage(event.data);
      });

    },

    /**
     * Обработка получения сообщения от браузера
     */
    onBrowserMessage(message) {
      if (message.action == "GO_TO_SCREEN") {
        this.resultBrowser.close();
      }

    },

    /**
     * Проверяем оплату
     */
    checkPayment() {

      if (this.needCheck) {
        this.autoOpen = false;
        this.fail = true;
        this.loadData();
      }

    },

    /**
     * Повторить попытку оплаты
     */
    retry() {

      this.autoOpen = true;
      this.waitPayment = false;
      this.fail = false;

      //если не нативная платформа
      if (!this.isNative) {
        //загрузим HTML для оплаты
        this.loadPymentInline();
      }
      //иначе открываем через отдельный инстанс браузера
      else {
        if (this.autoOpen) {
          this.openPayment();
        }
      }
    },

    /**
     * Открыть ссылку на оплату СБП, АльфаПей, Мир
     */
    openPaymentApp(url) {

      //закроем браузер с ПС
      if (this.paymentBrowser) {
        this.needCheck = false;
        this.paymentBrowser.close();
      }

      //запускаем приложение без проверок и then - всё равно плагин проверку 
      //на https не умеет делать и Then при открытии не работает
      //так что открываем в пустоту ...
      AppLauncher.openUrl({
        url: url,
      }).then((result) => {
        if (!result.completed) {
          bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: 'Не удалось открыть приложение банка. Проверьте установлено ли на Вашем устройстве приложение выбранного банка.', timeout: 10000 });
        }
      });

    },

    /**
     * Запускает таймер проверки оплаты
     */
    startCheckPaymentByTimer() {

      //сбрасываем таймер
      if (this.checkPaymentTimer > 0) {
        clearTimeout(this.checkPaymentTimer);
        this.checkPaymentTimer = 0;
      }

      this.waitPayment = true;
      //запустим таймер автоматической проверки оплаты заказа
      this.checkPaymentTimer = setTimeout(() => {
        this.checkPaymentByTimer(false);
      }, 5000);
    },

    /**
     * Останавливает таймер проверки оплаты
     */
    stopCheckPaymentByTimer() {

      this.waitPayment = false;

      //сбрасываем таймер
      if (this.checkPaymentTimer > 0) {
        clearTimeout(this.checkPaymentTimer);
        this.checkPaymentTimer = 0;
      }

    },

    /**
     * Проверяет оплачен ли заказ
     */
    checkPaymentByTimer(showError = true) {

      if (this.checkPaymentLoading) {
        return;
      }

      this.checkPaymentLoading = true;

      //сбрасываем таймер
      if (this.checkPaymentTimer > 0) {
        clearTimeout(this.checkPaymentTimer);
        this.checkPaymentTimer = 0;
      }

      rest
        .call("order.ispayed", {
          method: "post",
          data: {
            ID: this.id,
          },
        })
        .then((data) => {

          //если какая-то ошибка API
          if (!data.SUCCESS) {
            if (showError) {
              //показываем сообщение об ошибке
              bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            }
            return;
          }

          //если заказ оплачен
          if (data.PAYED && data.PAYED == 'Y') {

            this.waitPayment = false;
            this.order.FIELDS.PAYED = 'Y';

            if (this.timeoutTimer > 0) {
              clearTimeout(this.timeoutTimer);
            }

          } else {

            if (showError) {
              //показываем сообщение об ошибке
              bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: 'Заказ ещё не оплачен.' });
            }

            //запустим таймер автоматической проверки оплаты заказа
            this.checkPaymentTimer = setInterval(() => {
              this.checkPaymentByTimer(false);
            }, 5000);

          }

        }).finally(() => {
          this.checkPaymentLoading = false;
        });

    },

    /**
     * Открытие оплаты по SDK Юкасса
     */
    openYoomoneySDK() {

      bus.emit("OKRAINA_APP_E_PAYMENT_YOOMANEY_GET_TOKEN", {
        userId: this.order.FIELDS.USER_ID,
        orderId: this.order.FIELDS.ID,
        price: this.order.PAY_SYSTEM.SUM,
        onSuccess: (token, type) => {
          this.createPayYoomoneySDK(token, type);
        },
        onError: () => {
          this.fail = true;
        }
      });
    },

    /**
     * Создаёт платёж по токену через SDK Юкасса
     */
    createPayYoomoneySDK(token, type) {

      this.loading = true;
      //создаём платёж по токену
      rest
        .call("order.initpayment", {
          method: "post",
          data: {
            ID: this.id,
            OPTIONS: {
              TOKEN: token,
              //RETURN_URL: "okrainaapp://personal/order/payment/?ORDER_ID=" + this.id
            }
          },
        })
        .then((data) => {

          if (data.SUCCESS) {
            //если требуется подтверждение 3Ds
            if (data.DATA.status == 'pending') {
              this.confirmPayYoomoneySDK(data, type);
            }
            //если ошибка
            else if (data.DATA.status == 'canceled') {
              bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Произошла ошибки при оплате." });
              bus.emit("OKRAINA_APP_E_PAYMENT_YOOMANEY_CANCEL");
              this.fail = true;
            } else {
              this.waitPayment = false;
              this.order.FIELDS.PAYED = 'Y';
              bus.emit("OKRAINA_APP_E_PAYMENT_YOOMANEY_CANCEL");
            }

          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            bus.emit("OKRAINA_APP_E_PAYMENT_YOOMANEY_CANCEL");
            this.fail = true;
          }

        }).finally(() => {
          this.loading = false;
        });
    },

    /**
     * Подтверждение оплаты через SDK Юкасса (3DS или СБП или App2App)
     */
    confirmPayYoomoneySDK(data, type) {

      if (this.timeoutTimer > 0) {
        clearTimeout(this.timeoutTimer);
      }

      //запускаем проверку оплаты по таймеру
      this.startCheckPaymentByTimer();

      bus.emit("OKRAINA_APP_E_PAYMENT_YOOMANEY_CONFIRM", {
        confirmationUrl: data.DATA.confirmation.confirmation_url,
        type: type,
        onSuccess: () => {

          //при оплате через СБерПей 
          if (type.toLowerCase() == "sber" || type.toLowerCase() == "sberbank") {

            //бывает задержка получения сайтом уведолмения об оплате
            //поэтому ждём секунд 20

            this.timeoutTimer = setTimeout(() => {
              this.stopCheckPaymentByTimer();
              //проверим статус оплаты
              this.checkPaymentSDK();

            }, 20 * 1000);

          } else {

            this.stopCheckPaymentByTimer();
            //проверим статус оплаты
            this.checkPaymentSDK();
          }

        },
        onError: (error) => {

          this.stopCheckPaymentByTimer();

          bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: error.message });
          bus.emit("OKRAINA_APP_E_PAYMENT_YOOMANEY_CANCEL");
          this.fail = true;
        }
      });

    },

    /**
     * Проверяет статус оплаты по SDK
     */
    checkPaymentSDK() {

      this.loading = true;
      //проверяем результат (а то SDK по факту ошибку не возрвщает)
      rest
        .call("order.checkpayment", {
          method: "post",
          data: {
            ID: this.id
          },
        })
        .then((data) => {

          if (data.SUCCESS) {

            this.waitPayment = false;
            this.order.FIELDS.PAYED = 'Y';
          } else {
            this.fail = true;
          }

        }).finally(() => {
          this.loading = false;
        });

    },

    /**
     * Открытие оплаты SberPay SDK
     */
    openSberPaySDK() {

      //проверяем доступность мобильного приложения Сбера
      bus.emit("OKRAINA_APP_E_PAYMENT_SBERPAY_AVAILABLE", {
        callback: (result) => {

          if (!result.ready) {
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: result.errorMessage });
            this.fail = true;
            return;
          }

          //запускаем оплату
          this.paySberPaySDK();
        }
      });

    },

    /**
     * Непосредственный запуск оплаты SberPay SDK
     */
    async paySberPaySDK() {
      //создаём платёж 
      this.loading = true;
      let data = await rest
        .call("order.initpayment", {
          method: "post",
          data: {
            ID: this.id,
            OPTIONS: {
              PLATFORM: this.platform
            }
          },
        })
      this.loading = false;

      if (data.SUCCESS) {

        let param = data.DATA.transactionAttributes.find(p => p.name == "SbolBankInvoiceId")
        let bankInvoiceId = param.value;
        let orderNumber = data.DATA.orderNumber;

        //запускаем проверку оплаты по таймеру
        this.startCheckPaymentByTimer();

        bus.emit("OKRAINA_APP_E_PAYMENT_SBERPAY_PAY", {
          bankInvoiceId: bankInvoiceId,
          orderNumber: orderNumber,
          callback: (result) => {

            this.stopCheckPaymentByTimer();
            //пользователь отменил оплату
            if (result.status == "cancel") {
              this.fail = true;
            }
            //обработка платежа в процессе
            else if (result.status == "processing") {
              //нужно подождать
              this.startCheckPaymentByTimer();
            }
            //ошибка
            else if (result.status == "error") {
              bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: result.errorMessage });
            }
            //успешно
            else if (result.status == "success") {
              //нужно подождать
              this.startCheckPaymentByTimer();
              //но сразу запускаем проверку,а не ждём 5 сек
              this.checkPaymentByTimer(false);
            }
          }
        });

      } else {
        //показываем сообщение об ошибке
        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
        this.fail = true;
      }
    },


    /**
     * Сохраняет данные для восстановления приложения из background
     */
    saveDataForRestore() {
      return {
        "component": this.$options.name,
        "data": this.$data
      }
    },

    /**
     * Восстановление данных при восстановлении приложения из background
     */
    restoreData() {
      let restoreData = this.$store.getters.getRestoreData;
      let params = restoreData.find((p) => p.component == this.$options.name);
      if (params) {
        for (var key in params.data) {
          let value = params.data[key];
          this.$data[key] = value;
        }

        //стираем данные. чтобы при reload не применились опять
        restoreData = restoreData.filter(p => p != params);
        this.$store.commit("setRestoreData", restoreData);
      }
    },

    /**
     * Клик по инлайн-HTML
     */
    onPaymentHtmlClick(e) {

      //кли по кнопке "SberPay"
      if (e.target.className == "sp-btn") {
        //создаём плагин
        let widget = createWidget("PRODUCTION");
        widget.open({
          bankInvoiceId: e.target.getAttribute("data-invoice-id"),
          backUrl: e.target.getAttribute("data-back-url")
        });

      }


    },

    /**
     * Переход к просмотру заказа
     */
    goToOrderDetail() {
      this.$router.replace({ name: "order-detail", params: { id: this.id } });
    }

  },

  /**
   * Обработка перед размонтированием
   */
  beforeUnmount() {
    this.unsubscribe();

    if (this.timeoutTimer > 0) {
      clearTimeout(this.timeoutTimer);
    }
  },

  /**
   * Обработка создания компонента
   */
  created() {

    //восстановление данных
    this.restoreData();

    //если удалось восстановить заказ из restore 
    if (this.order) {
      //если был запущен таймер проверки оплаты
      if (this.checkPaymentTimer > 0) {

        this.startCheckPaymentByTimer();
        this.checkPaymentLoading = false;
        this.checkPaymentByTimer(false);
      }

    } else {
      this.autoOpen = true;
      this.loadData();

    }


  },

  mounted() {
    //подписываемся на сохранение данных для восстановления приложения из background
    this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
  },

  unmounted() {
    //отписываемся от сохранения данных для восстановления приложения из background
    this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
  },
};
</script>

<style lang="scss">
.okraina-v-order-pay {
  .payment {
    margin-top: 40px;
  }

  .fail {
    text-align: center;

    .btn {
      margin-top: 15px;
    }
  }

  .success {
    text-align: center;
  }

  .canceled {
    text-align: center;
  }

  .btns-wrap {
    padding: 8px 35px;

    .btn {
      margin-bottom: 8px;
    }
  }

  .nspk {
    text-align: center;

    .btn {
      margin-top: 15px;
      margin-bottom: 15px;
    }

  }
}
</style>
