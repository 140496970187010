<template>
    <okraina-view :class="'okraina-v-recipes-list'" @refresh="refresh" ref="view" :hideMenu="true"
        :contentHorizontalPadding="16">

        <template v-slot:header>
            <h1>
                Рецепты
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <template v-if="canShow">

                <div class="list row">

                    <div class="col-12 col-sm-6 col-md-4" v-for="item in items" :key="item.FIELDS.ID">
                        <a class="item" :href="item.FIELDS.DETAIL_PAGE_URL"
                            @click.prevent="goto(item.FIELDS.DETAIL_PAGE_URL)" v-ripple>

                            <div class="picture preloaded" v-if="item.FIELDS.PREVIEW_PICTURE"
                                v-lazy:background-image="item.FIELDS.PREVIEW_PICTURE.SRC">

                                <div class="preload"
                                    :style="{ backgroundImage: 'url(\'' + item.FIELDS.PREVIEW_PICTURE.PRELOAD + '\')' }">
                                </div>

                                <div class="loader">
                                    <okraina-loader-inline />
                                </div>

                                <div class="shadow"></div>
                                <div class="time" v-if="item.PROPS.COOKING_TIME">{{ item.PROPS.COOKING_TIME }}</div>
                            </div>
                            <div class="picture preloaded" v-else-if="item.FIELDS.DETAIL_PICTURE"
                                v-lazy:background-image="item.FIELDS.DETAIL_PICTURE.SRC">

                                <div class="preload"
                                    :style="{ backgroundImage: 'url(\'' + item.FIELDS.DETAIL_PICTURE.PRELOAD + '\')' }">
                                </div>

                                <div class="loader">
                                    <okraina-loader-inline />
                                </div>

                                <div class="shadow"></div>
                                <div class="time" v-if="item.PROPS.COOKING_TIME">{{ item.PROPS.COOKING_TIME }}</div>
                            </div>

                            <div class="inner">
                                <div class="name" v-html="item.FIELDS.NAME"></div>
                            </div>


                        </a>
                    </div>

                </div>

            </template>


            <div ref="pager" class="pager"></div>

            <!-- Подгрузка следюущей страницы -->
            <template v-if="loadingPaging">
                <div class="text-center">
                    <okraina-loader-inline />
                </div>
            </template>

        </template>

    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/clients.recipes.list.js";

export default {
    name: "okraina-v-recipes-list",
    components: {
        okrainaLoaderPage,
        okrainaView,
        okrainaLoaderInline,
    },
    data() {
        return {
            loading: true,
            loadingPaging: false,
            items: [],
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 12,
                PAGES_COUNT: 1,
            },
            canShow: false
        };
    },

    methods: {

        /**
         * Загружает данные
         */
        loadData(resolve = false) {
            if (!this.loading) {
                this.loading = resolve ? false : true;
            }

            rest
                .call("recipes.getlist", {
                    method: "post",
                    data: {
                        NAV_PARAMS: this.pager,
                        RESIZE_OPTIONS: {
                            WIDTH: 550,
                            HEIGHT: 400,
                            TYPE: "EXACT",
                            PRELOAD: "Y"
                        }
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    if (resolve) {
                        resolve();
                    }
                });
        },

        /**
         * Перезагрузка экрана
         */
        refresh(resolve) {
            this.pager.NUM_PAGE = 1;
            this.loadData(resolve);
        },

        /**
         * Скрол экрана
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;

            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingPaging &&
                    !this.loading &&
                    this.canShow &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData(() => { });
                }
            }
        },

        /**
         * Переход на указанный URL
         */
        goto(url) {
            setTimeout(() => {
                this.$router.push({ path: url });
            }, 400);
        },
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },

    /**
     * Вызывается перед переходом из этого экрана в другой
     */
    beforeRouteLeave() {
        this.$refs.view.onBeforeRouteLeave();
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {
        //запишем мета данные страницы
        meta.write(metaTemplates);

        next((vm) => {
            vm.$refs.view.onBeforeRouteEnter();
        });
    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

}
</script>

<style lang="scss">
.okraina-v-recipes-list {

    .item {
        border-radius: 5px;
        background-color: var(--color-block-background);
        overflow: hidden;
        margin-bottom: 16px;
        border-bottom: 0px;
        display: block;

        .picture {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
            padding-bottom: 72.22%;
            padding-top: 0;
            height: 0;

            .shadow {
                position: absolute;
                bottom: 0px;
                left: 0px;
                right: 0px;
                height: 50%;

                background: linear-gradient(to top, rgba(0, 0, 0, .638) 0%, rgba(0, 0, 0, .538) 19%, rgba(0, 0, 0, .441) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
                background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .638) 0%, rgba(0, 0, 0, .538) 19%, rgba(0, 0, 0, .441) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
                background: -moz-linear-gradient(bottom, rgba(0, 0, 0, .638) 0%, rgba(0, 0, 0, .538) 19%, rgba(0, 0, 0, .441) 34%, rgba(0, 0, 0, .382) 47%, rgba(0, 0, 0, .278) 56.5%, rgba(0, 0, 0, .194) 65%, rgba(0, 0, 0, .126) 73%, rgba(0, 0, 0, .075) 80.2%, rgba(0, 0, 0, .042) 86.1%, rgba(0, 0, 0, .021) 91%, rgba(0, 0, 0, .008) 95.2%, rgba(0, 0, 0, .002) 98.2%, transparent 100%);
            }

            .time {
                position: absolute;
                bottom: 15px;
                left: 16px;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0px;
            }
        }

        .inner {
            padding: 12px 16px 12px 16px;
        }

        .name {
            font-weight: 600;
            font-size: 15px;

            color: var(--color-text-title);
        }
    }
}
</style>