<template>
    <div class="okraina-region">
        <div>
            <div class="region" @click="onRegionClick">
                <span class="icon fi fi-location"></span>
                <span class="name">
                    <template v-if="region">{{ region.FIELDS.NAME }}</template>
                    <template v-else>Выбрать</template>
                </span>
            </div>
        </div>
        <div>
            <a v-if="region" class="call" :href="'tel:' + region.PROPS.PHONE">
                <span class="fi fi-call"></span>
            </a>
        </div>

        <okraina-sheet v-model:visible="show" :maxHeight="90">
            <template v-slot:body>
                <div class="okraina-region-select">
                    <div class="sheet-title text-center">Выберите Ваш регион</div>

                    <div class="items">
                        <div class="item-wrap" v-for="item in regions" :key="item.FIELDS.ID">
                            <div class="item" @click="onItemClick(item)">
                                <div class="picture"
                                    :style="{ 'background-image': 'url(\'' + item.FIELDS.PREVIEW_PICTURE.SRC + '\')' }">
                                </div>
                                <div class="name">{{ item.FIELDS.NAME }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </okraina-sheet>
    </div>
</template>

<script>

import okrainaSheet from "@/components/sheet.vue";

import { mapGetters } from "vuex";

import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

export default {
    name: "okraina-region",
    components: {
        okrainaSheet,
    },
    emits: ["change"],
    data() {
        return {
            show: false,
            active: true
        };
    },

    computed: {
        ...mapGetters({
            regions: "getRegions",
            connection: "getConnection"
        }),

        region() {
            let item = this.regions.find(r => r.PROPS.DOMAIN == this.connection.server);
            return item;
        }
    },

    watch: {
        regions: function () {
            this.preloadPictures();
        },
    },

    methods: {
        onRegionClick() {
            this.show = true;
        },

        /**
         * Клик по региону
         */
        onItemClick(item) {
            this.show = false;

            //вызываем смену региона
            bus.emit("OKRAINA_APP_E_CHANGE_REGION", { domain: item.PROPS.DOMAIN, showFullScreenBaners: true });

            //сообщаем вышестоящему компоненту об изменении региона
            this.$emit("change");
        },

        /**
         * Подгружает фото регионов заранее
         */
        preloadPictures() {

            let images = this.regions.map(r => r.FIELDS.DETAIL_PICTURE.SRC);

            tools.preloadImages(images);
        },

        open() {
            if (this.active) {
                this.show = true;
            }
        }


    },

    activated() {
        this.active = true;
    },

    deactivated() {
        this.active = false;
    },

    mounted() {
        bus.on("OKRAINA_REGION_E_OPEN", this.open);
    },

    unmounted() {
        bus.off("OKRAINA_REGION_E_OPEN", this.open);
    },
};
</script>

<style lang="scss">
.okraina-region {
    color: var(--color-text-top);

    display: grid;
    grid-template-columns: 1fr auto;
    height: 48px;
    align-items: center;


    .region {
        display: inline-block;
        position: relative;
        padding: 10px 8px;
        padding-left: 35px;

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
        }

        .name {
            border-bottom: 1px dashed currentColor;
        }

    }


    .call {
        display: block;
        border-bottom: 0px;
        color: inherit;
        width: 50px;
        padding: 10px 0px;
        text-align: center;
    }





}

.okraina-region-select {

    .items {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 16px;
    }

    .item {
        max-width: 150px;
        margin: 0px auto;

        .picture {
            height: 70px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            margin-bottom: 10px;
        }

        .name {
            text-align: center;
            font-weight: 500;
            ;
        }

    }
}
</style>
