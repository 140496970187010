<template>
  <div class="okraina-cl" ref="root">

    <h2 v-if="title && items.length > 0">{{ title }}</h2>

    <!-- Перезагрузка -->
    <template v-if="loadingReload">
      <div class="text-center">
        <okraina-loader-inline />
      </div>
    </template>

    <div class="list" v-if="!loadingReload">
      <div class="item-wrap" v-for="item in items" :key="item.FIELDS.ID">
        <okraina-catalog-item :item="item" :recommendationId="recommendationId" />
      </div>
    </div>
    <div ref="pager" class="pager"></div>

    <!-- Подгрузка следюущей страницы -->
    <template v-if="loadingPaging">
      <div class="text-center">
        <okraina-loader-inline />
      </div>
    </template>

    <template v-if="!loadingCommon && items.length == 0">
      <template v-if="isSearch">
        <okraina-not-found caption="Продуктов по вашему запросу не найдено"
          details="Попробуйте изменить запрос или воспользуйтесь каталогом" :absolute="false">
          <div class="btn btn1 slim" @click="goToCatalog">Перейти в каталог</div>
        </okraina-not-found>
      </template>
    </template>
  </div>
</template>

<script>
import okrainaCatalogItem from "@/components/catalog/item.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaNotFound from "@/components/not.found.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import equal from 'equals';
import tools from "@/plugins/tools";

import imageNoResultsPath from "@/assets/images/no-results.svg";

export default {
  name: "okraina-catalog-list",
  components: {
    okrainaCatalogItem,
    okrainaLoaderInline,
    okrainaNotFound
  },
  emits: ["update:loading"],
  data() {
    return {
      loadingPaging: false,
      loadingReload: true,
      loadingCommon: false,
      items: [],
      pager: {
        NUM_PAGE: 1,
        PAGE_SIZE: 12,
        PAGES_COUNT: 1,
      },
      imageNoResults: imageNoResultsPath,
    };
  },
  props: {
    title: {
      type: String,
      default() {
        return ""
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    filter: {
      type: Object,
      default() {
        return {};
      },
    },
    sort: {
      type: [Object, Boolean],
      default() {
        return {};
      },
    },
    refreshIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    isSearch: {
      type: Boolean,
      default() {
        return false;
      },
    },
    recommendationId: {
      type: [String, Number],
      default() {
        return "";
      }
    }
  },
  watch: {
    refreshIndex: function () {
      this.pager.NUM_PAGE = 1;
      this.loadingReload = true;
      this.loadData();
    },
    sort: function () {
      this.pager.NUM_PAGE = 1;
      this.loadingReload = true;
      this.loadData();
    },
    filter(newval, oldVal) {
      if (equal(newval, oldVal)) {
        return;
      }
      this.pager.NUM_PAGE = 1;
      this.loadingReload = true;
      this.loadData();
    },
  },
  methods: {
    /**
     * Загружает данные
     */
    loadData() {
      this.loadingCommon = true;
      //запрашивает даные
      rest
        .call("catalog.products.getlist", {
          method: "post",
          data: {
            FILTER: this.filter,
            ORDER: this.sort,
            NAV_PARAMS: this.pager,
            SEARCH: this.isSearch ? "Y" : "N",
            RESIZE_OPTIONS: tools.getProductListResizeOptions(),
          },
        })
        .then((data) => {
          if (data.SUCCESS) {
            if (this.pager.NUM_PAGE == 1) {
              this.items = data.ITEMS;
            } else {
              this.items = this.items.concat(data.ITEMS);
            }
            this.pager = data.PAGER;
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.$emit("update:loading", false);
          this.loadingPaging = false;
          this.loadingReload = false;
          this.loadingCommon = false;
        });
    },

    /**
     * Скрол экрана
     */
    onScroll(e) {
      let scroll = e.target.scrollTop;
      let height = e.target.offsetHeight;
      let offsetTop = this.$refs.pager.offsetTop;

      //если экран прокрутился до пейджера
      if (scroll + height > offsetTop) {
        //если экран не в стадии загрузки и есть следующие страницы
        if (
          !this.loadingPaging &&
          !this.loadingReload &&
          !this.loading &&
          this.pager &&
          this.pager.NUM_PAGE < this.pager.PAGES_COUNT
        ) {
          this.pager.NUM_PAGE++;
          this.loadingPaging = true;
          this.loadData();
        }
      }
    },

    /**
     * Переход в каталог
     */
    goToCatalog() {
      this.$router.push({ name: "catalog" });
    },
  },

  created() {
    this.loadData();
  },

  mounted() {
    //подписываемся на скролинг экрана
    this.$refs.root
      .closest(".view-content-scroll")
      .addEventListener("scroll", this.onScroll);
  },

  beforeUnmount() {
    //отписываесмся от скролинга экрана
    this.$refs.root
      .closest(".view-content-scroll")
      .removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.okraina-cl {
  .list {
    display: grid;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-gap: 8px;
  }

  @media (min-width: 600px) {
    .list {
      grid-template-columns: calc(33.333% - 4px) calc(33.333% - 4px) calc(33.333% - 4px);
    }
  }

  @media (min-width: 800px) {
    .list {
      grid-template-columns: calc(25% - 4px) calc(25% - 4px) calc(25% - 4px) calc(25% - 4px);
    }
  }

  .pager {
    margin-bottom: 40px;
  }

}
</style>
