<template>
    <div class="okraina-c-ri" :class="{ 'show-actions': showActions }">

        <div class="overlay" @click="closeActions"></div>

        <div class="author">
            <div class="name">{{ name }}</div>
            <div class="stars-date">
                <div>
                    <div class="stars">
                        <div class="star" :class="{ selected: i <= item.ANSWERS.MARK }" v-for="i in 5" :key="i">
                            <span class="fi fi-star"></span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="date">{{ dateFormatted }}</div>
                </div>
            </div>

        </div>

        <div class="actions-wrap">
            <div class="actions-btn" v-ripple @click="openActions">
                <span class="icon fi fi-dots"></span>
            </div>

            <div class="actions">
                <div class="action" v-ripple="'rgba(255, 255, 255, 0.15)'" @click="onComplaintClick">Пожаловаться на
                    публикацию</div>
                <div class="action" v-ripple="'rgba(255, 255, 255, 0.15)'" @click="onBlockClick">Заблокировать
                    пользователя</div>
            </div>

        </div>

        <div class="photos" v-if="item.ANSWERS.FILES">
            <div class="photo" v-for="(photo, index) in item.ANSWERS.FILES" :key="index"
                v-lazy:background-image="photo.PREVIEW.SRC" @click="openPhoto(index)">
            </div>
        </div>
        <div class="text" v-html="text" @click="expandText"></div>
    </div>
</template>

<script>

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import moment from "moment";

import tools from '@/plugins/tools.js'

import { mapGetters } from "vuex";

export default {
    name: "okraina-clients-review-item",
    components: {

    },
    emits: ["complaint", "block"],
    data() {
        return {
            showActions: false,
            collapseText: true
        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },

    },

    computed: {
        ...mapGetters({
            isAuthorized: "isAuthorized"
        }),

        name() {
            return this.item.ANSWERS.NAME ? this.item.ANSWERS.NAME : "";
        },
        symbol() {
            return this.name ? this.name.charAt(0) : "";
        },
        dateFormatted() {
            return moment(this.item.ANSWERS.DATE, "DD.MM.YYYY HH:mm:ss").format("DD MMMM YYYY HH:mm");
        },
        gallery() {
            if (!this.item.ANSWERS.FILES) {
                return [];
            }
            let images = this.item.ANSWERS.FILES.map((photo) => {
                return {
                    src: photo.FULL.SRC,
                    type: "image",
                    width: photo.FULL.WIDTH,
                    height: photo.FULL.HEIGHT,
                    id: photo.FULL.ID,
                };
            });
            return images;
        },

        /**
         * Текст без html
         */
        textWithoutHtml() {
            return this.item.ANSWERS.REVIEW.replace(/(<([^>]+)>)/gi, "").trim();
        },

        /**
         * Текст, по умолчанию свёрнуто
         */
        text() {

            if (!this.textWithoutHtml) {
                return "";
            }

            if (this.collapseText) {

                if (this.textWithoutHtml.length > 120) {
                    return this.textWithoutHtml.substring(0, 120) + " ... <span class='a'>развернуть</span>";
                }

            }

            return this.item.ANSWERS.REVIEW;
        }
    },

    methods: {

        /**
         * Открыть фото на просмотр
         */
        openPhoto(start) {

            Fancybox.show(this.gallery, {
                startIndex: start,
                preload: 1,
                infinite: false,
                Toolbar: {
                    display: [
                        { id: "prev", position: "center" },
                        { id: "counter", position: "center" },
                        { id: "next", position: "center" },
                        "zoom",
                        "close",
                    ],
                },
                Thumbs: {
                    autoStart: false,
                },
                Hash: false,
                on: {
                    ready: (fancybox) => {
                        tools.fancyBoxOnReady(fancybox);
                    },
                    closing: () => {
                        tools.fancyBoxOnClose();
                    },

                },
            });
        },

        /**
         * Открыть действия
         */
        openActions() {
            this.showActions = true;

            //эмитируем открытие страницы, чтобы по клику кнопки возврата закрыть
            window.history.pushState({}, "", window.location.href + "?actionsOpen");

            //добавляем закрытие в стэк обработчиков возврата назад
            this.$store.commit("addToBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть
         */
        closeFromBaсkBtn() {
            this.showActions = false;

            //удаляем закрытие из стэка обработчиков возврата назад
            this.$store.commit("removeFromBackStack", this.closeFromBaсkBtn);
        },

        /**
         * Закрыть
         */
        closeActions() {
            //эмитируем переход назад
            history.back();
        },

        /**
         * Клик по действию "Пожаловаться на публикацию"
         */
        onComplaintClick() {

            this.closeActions();
            setTimeout(() => {
                this.$emit("complaint", {
                    AUTHOR_ID: this.item.FIELDS.USER_ID,
                    REVIEW_ID: this.item.FIELDS.ID,
                    REVIEW_TEXT: this.item.ANSWERS.REVIEW
                });
            }, 300);

        },

        /**
         * Клик по действию "Заблокирвоать пользователя"
         */
        onBlockClick() {
            this.closeActions();
            setTimeout(() => {
                this.$emit("block", {
                    AUTHOR_ID: this.item.FIELDS.USER_ID
                });
            }, 300);

        },

        /**
         * Разворачивает текст отзыва
         */
        expandText() {
            this.collapseText = false;
        }

    }
}

</script>

<style lang="scss">
.okraina-c-ri {
    margin-bottom: 30px;
    position: relative;

    .author {
        position: relative;
        margin-bottom: 10px;

        .avatar {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: var(--color-review-avatar-background);


            .symbol {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 28px;
                font-weight: bold;
                color: var(--color-review-avatar);
                line-height: 1;
            }
        }

        .name {
            color: var(--color-review-name);
        }

        .stars-date {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 7px;
            align-items: center;
        }

        .stars {
            &::after {
                content: "";
                display: block;
                clear: both;
            }
        }

        .star {
            float: left;
            margin-right: 4px;
            font-size: 11px;
            color: var(--color-control-background);

            &.selected {
                color: var(--color-icon-star);
            }
        }

        .date {
            color: var(--color-review-date);
            font-size: 10px;
        }
    }

    .photos {
        margin-bottom: 10px;

        &::after {
            content: "";
            display: block;
            clear: both;
        }

        .photo {
            float: left;
            width: 52px;
            height: 52px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    .text {
        color: var(--color-review-text);
        font-size: 13px;

        .a {
            border-bottom: 0px;
        }
    }

    .overlay {
        position: fixed;
        visibility: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1100;
        transition: opacity 0.2s ease-out, visibility 0s linear 200ms;
        background-color: #000;
        opacity: 0;
    }

    .actions-wrap {
        position: absolute;
        top: -10px;
        right: 0px;

        .actions-btn {
            position: relative;
            width: 30px;
            height: 30px;
            transform: rotate(90deg);
            border-radius: 50%;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

        }

        .actions {
            position: absolute;
            visibility: hidden;
            overflow: hidden;
            top: 100%;
            right: -24px;
            padding: 0px;
            background-color: var(--color-color1);
            z-index: 1300;
            border-radius: 20px 0px 0px 20px;
            transition: transform 0.2s ease-out, visibility 0s linear 200ms;
            transform: scale(0, 0);
            transform-origin: top right;

            .action {
                white-space: nowrap;
                color: var(--color-text-top);
                font-size: 13px;
                padding: 10px 20px;
            }
        }

    }

    &.show-actions {
        .overlay {
            visibility: visible;
            opacity: 0.5;
            transition: opacity 0.2s ease-out, visibility 0s linear;
        }

        .actions {
            visibility: visible;
            transform: scale(1, 1);
            transition: transform 0.2s ease-out, visibility 0s linear;
        }
    }
}
</style>