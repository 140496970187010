<template>
    <div class="okraina-tabs">
        <div v-for="item in items" :key="item.id" class="tab" :class="{ selected: modelValue == item.id }" v-ripple
            @click="onClick(item.id)">
            {{ item.name }}
        </div>
    </div>
</template>

<script>

export default {
    name: "okraina-tabs",
    emits: ["update:modelValue"],
    components: {

    },
    data() {
        return {
        }
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },

    },


    methods: {

        onClick(id) {
            this.$emit("update:modelValue", id);
        }

    },



}

</script>

<style lang="scss">
.okraina-tabs {
    margin-bottom: 20px;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .tab {
        position: relative;
        display: block;
        float: left;
        padding: 10px 15px 10px 15px;
        border-radius: 80px;
        background-color: var(--color-tab-background);
        color: var(--color-tab-text);
        font-size: 13px;
        margin-right: 8px;
        margin-bottom: 8px;

        &.selected {
            background-color: var(--color-tab-selected-background);
            color: var(--color-tab-selected-text);
        }


    }
}
</style>