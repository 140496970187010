<template>
    <transition>
        <div class="okraina-fsc" v-if="visible">

            <template v-if="loading">
                <div class="loading">
                    <okraina-loader-inline />
                </div>
            </template>

            <template v-else>

                <div class="toolbar">

                    <div class="pager" :class="{ stop: stop }">
                        <div class="page " :class="{ selected: i <= index }" v-for="(p, i) in items" :key="p.FIELDS.ID">
                        </div>
                    </div>

                    <div class="close" @click="close">
                        <span class="icon fi fi-close"></span>
                    </div>
                </div>

                <!-- Таперы слева/справа-->
                <div class="left tap" @click="onTapLeft" @touchstart="onTouchStart($event, 'left')"
                    @touchmove="onTouchMove($event, 'left')" @touchend="onTouchEnd($event, 'left')"></div>
                <div class="right tap" @click="onTapRight" @touchstart="onTouchStart($event, 'right')"
                    @touchmove="onTouchMove($event, 'right')" @touchend="onTouchEnd($event, 'right')"></div>

                <swiper :slidesPerView="1" :loop="false" :spaceBetween="0" :autoHeight="false" :allowTouchMove="false"
                    @swiper="onSwiper" @destroy="onSwiperDestroy">
                    <swiper-slide v-for="item in items" :key="item.FIELDS.ID">
                        <div class="item" @click="onItemClick(item)">
                            <div class="image preloaded" v-if="item.FIELDS.DETAIL_PICTURE"
                                v-lazy:background-image="item.FIELDS.DETAIL_PICTURE.SRC">
                                <div class="loader">
                                    <okraina-loader-inline />
                                </div>
                            </div>

                        </div>
                    </swiper-slide>
                </swiper>
            </template>


        </div>
    </transition>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import storage from '@/plugins/storage'
import { mapGetters } from "vuex";

export default {
    name: "okraina-sliders-fullscreen-banners",
    components: {
        okrainaLoaderInline,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            loading: false,
            items: [],
            index: -1,
            swiper: false,
            visible: false,
            watchVisible: false,
            timer: 0,
            waitSwiperResolve: false,
            stop: false,
            touches: {
                left: {},
                right: {}
            }
        };
    },

    computed: {
        ...mapGetters({
            platform: "getPlatform"
        }),

    },

    watch: {
        swiper: function () {
            if (this.swiper && this.waitSwiperResolve) {
                this.waitSwiperResolve(true);
            }
        },
        visible: function () {
            //меняем у строки навигации флаг прозрачности
            bus.emit("OKRAINA_NAVIGATION_BAR_E_SET_TRANSPARENT", this.visible)
            if (this.visible) {
                //скроем статус бар
                //bus.emit("OKRAINA_OKRAINA_STATUS_BAR_E_HIDE");
            } else {
                //покажем статус бар
                //bus.emit("OKRAINA_OKRAINA_STATUS_BAR_E_SHOW");
            }
        }
    },

    methods: {

        /**
         * Вспомогательный метод для ожидания инициализации свайпера
         */
        waitSwiper() {
            return new Promise(resolve => {
                if (this.swiper) {
                    resolve(true);
                } else {
                    this.waitSwiperResolve = resolve;
                }
            });
        },

        /**
         * Загружает данные
         */
        loadData() {
            this.loading = true;

            this.index = -1;
            rest
                .call("slides.fullscreen.getlist", {
                    method: "post",
                    data: {
                        RESIZE_OPTIONS: {

                        }
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        this.items = data.ITEMS;
                        //показываем только если есть слайды
                        if (this.items.length > 0) {
                            this.visible = true;
                            this.start();
                        }

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false
                });
        },

        /**
         * Запуск показа с таймером
         */
        start() {

            if (this.items.length == 0) {
                return;
            }

            let first = this.items[0];

            //прогрузим фоново первый баннер
            tools.preloadImages([first.FIELDS.DETAIL_PICTURE.SRC]).then(async () => {

                await this.waitSwiper();

                this.timer = setTimeout(() => {
                    this.index = 0;
                    this.next();
                }, 100);
            });


        },

        /**
         * Переход к следующему слайду
         */
        next() {
            this.timer = setTimeout(() => {
                this.index++;

                if (this.index == this.items.length) {
                    this.close();
                    return;
                }

                this.swiper.slideNext(300, true);
                this.next();
            }, 4000);

        },

        /**
         * Клик по области "влево"
         */
        onTapLeft() {

            //для первого слайда ничего не делаем
            if (this.index <= 0) {
                return
            }

            //останавливаем таймер прокрутки
            if (this.timer > 0) {
                clearTimeout(this.timer);
            }

            //вернёмя на 2 шага назад (нам нужно пропустить текущий слайд и предыдущий)
            this.index -= 2;
            this.swiper.slidePrev(300, true);
            //хак для блокировки анимации пэйджера (вытсавим класс, отключающий анимацию)
            this.stop = true;
            //а на следующей тике убираем этот класс и увеличиваем шаг (как бы переходим уже по факту к предыдущему слайду)
            setTimeout(() => {
                this.stop = false;
                this.index++;
                this.next();
            });

        },

        /**
         * Клик по области "вправо"
         */
        onTapRight() {

            //для последнего слайда ничего не делаем
            if (this.index >= this.items.length - 1) {
                return
            }

            //останавливаем таймер прокрутки
            if (this.timer > 0) {
                clearTimeout(this.timer);
            }
            //this.index ++;
            this.swiper.slideNext(300, true);
            //хак для блокировки анимации пэйджера (вытсавим класс, отключающий анимацию)
            this.stop = true;
            //а на следующей тике убираем этот класс и увеличиваем шаг (как бы переходим уже по факту к предыдущему слайду)
            setTimeout(() => {
                this.stop = false;
                this.index++;
                this.next();
            });

        },

        /**
         * Старт тача на тапе влево/вправо
         */
        onTouchStart(e, side) {
            this.touches[side].start = true;
            this.touches[side].startClientX = e.touches[0].clientX
            this.touches[side].clientX = e.touches[0].clientX
        },

        /**
         * Движение тача на тапе влево/вправо
         */
        onTouchMove(e, side) {
            this.touches[side].clientX = e.touches[0].clientX
        },

        /**
         * Завершение тача на тапе влево/вправо
         */
        onTouchEnd(e, side) {

            //если тач был запущен до этого
            if (this.touches[side].start) {
                this.touches[side].start = false

                if (side == 'left') {
                    if (this.touches[side].clientX - this.touches[side].startClientX > 70) {
                        e.preventDefault();
                        this.onTapLeft();
                    }
                } else if (side == 'right') {
                    if (this.touches[side].startClientX - this.touches[side].clientX > 70) {
                        e.preventDefault();
                        this.onTapRight();
                    }
                }

            }
        },

        /**
         * Обработка завершения инициаизации свайпера
         */
        onSwiper(swiper) {
            this.swiper = swiper;
        },

        /**
         * Обработка удаления свайпера
         */
        onSwiperDestroy() {
            this.swiper = false;
        },

        /**
         * Закрывает
         */
        close() {
            if (this.timer > 0) {
                clearTimeout(this.timer);
            }
            this.visible = false;
            this.index = -1;
        },

        /**
         * Клик по слайду
         */
        onItemClick(item) {

            this.close();

            //акция
            if (item.PROPS.TYPE.XML_ID == 'ACTION') {
                this.$router.push({ name: "actions-detail", params: { code: item.PROPS.ACTION_CODE } });
            }
            //товар 
            else if (item.PROPS.TYPE.XML_ID == 'CATALOG_PRODUCT') {
                bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                    id: item.PROPS.CATALOG_PRODUCT_ID,
                    code: item.PROPS.CATALOG_PRODUCT_CODE
                });
            }
            //новость
            else if (item.PROPS.TYPE.XML_ID == 'NEWS') {
                this.$router.push({ name: "news-detail", params: { code: item.PROPS.NEWS_CODE } });
            }
            //раздел каталога
            else if (item.PROPS.TYPE.XML_ID == 'CATALOG_SECTION') {
                this.$router.push({ path: item.PROPS.CATALOG_SECTION_URL });
            }
            else {
                this.$router.push({ path: item.PROPS.EXTERNAL_URL });
            }

        },

        /**
         * Показ баннера
         */
        async show(forced = false) {
            if (this.visible) {
                return;
            }

            let currentTime = Math.floor(Date.now() / 1000);
            //Если требуется показать принудительно
            if (forced) {

                this.loadData();
                storage.set("fsb_lst", "" + currentTime);
            } else {

                //время последнего показа
                let lastShowTime = await storage.get("fsb_lst");
                if (lastShowTime == null || parseInt(lastShowTime) + 24 * 60 * 60 < currentTime) {

                    this.loadData();
                    storage.set("fsb_lst", "" + currentTime);
                }

            }

        },

    },

    created() {
        //подписваемся на событие 
        bus.on("OKRAINA_FULLSCREEN_BANERS_E_SHOW", this.show);

    },

    unmounted() {
        if (this.timer > 0) {
            clearTimeout(this.timer);
        }
    }
}

</script>

<style lang="scss">
.okraina-fsc {
    position: fixed;
    z-index: 5000;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: var(--color-view-background);
    box-shadow: rgba(1, 0, 61, 0.15) 0px 0px 15px;

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .swiper {
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;

    }

    .item {
        position: relative;
        height: 100%;

        .image {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

        }
    }

    .tap {
        position: absolute;
        top: calc(60px + var(--safe-area-top, 0px));
        bottom: 0px;
        width: 25%;
        z-index: 10;

        &.left {
            left: 0px;
        }

        &.right {
            right: 0px;
        }
    }

    .toolbar {
        position: absolute;
        top: calc(5px + var(--safe-area-top, 0px));
        left: 0px;
        right: 0px;
        padding-top: 5px;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 10;

        .pager {
            display: grid;
            column-gap: 7px;

            grid-template-columns: repeat(auto-fit, minmax(15px, 1fr));

            .page {
                position: relative;
                height: 3px;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    z-index: 1;
                    opacity: 0.2;
                    background-color: #000000;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    bottom: 0px;
                    width: 0%;
                    z-index: 2;
                    background-color: #fff;
                    transition: width 4s linear;
                }

                &.selected::after {
                    width: 100%;
                }
            }

            &.stop {
                .page {
                    &::after {
                        transition: none;
                    }
                }
            }
        }


        .close {
            position: absolute;
            top: 20px;
            right: 15px;
            width: 30px;
            height: 30px;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 16px;
            }
        }
    }

    /*
     Анимация появления:
    */

    &.v-enter-active {
        transition: transform 0.4s ease;
    }

    &.v-enter-from {
        transform: scale(0.1);
    }

    &.v-enter-to {
        transform: scale(1);
    }


}
</style>