<template>
  <div class="okraina-network-error" :class="open ? 'open' : ''">
    <div class="inner">
      <div class="container">

        <okraina-illustration :picture="imageNetwork" caption="Нет соединения"
          details="Возможно, у Вас включен VPN или проблемы у нас. <br/> <br/>Чтобы не ждать, пока мы всё починим, позвоните нам и закажите - мы доставим заказ в срок!">

          <div class="btn btn1 btn-block slim" :class="loading ? 'wait' : ''" v-ripple v-on:click="onRefreshClick">
            Повторить
            <div v-if="loading" class="btn-loader"></div>
          </div>
          <br />
          <div v-if="innerRegions" class="contacts-regions">
            <a v-for="region in innerRegions" :key="region.FIELDS.ID" class="region btn btn3 btn-block slim" :href="'tel:' + region.PROPS.PHONE">Позвонить ({{
            region.FIELDS.NAME }})</a>
          </div>


        </okraina-illustration>

      </div>
    </div>
  </div>
</template>

<script>
import okrainaIllustration from "@/components/illustration.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";
import store from "@/store";
import storage from '@/plugins/storage'

import imageNetworkPath from "@/assets/images/network.svg";

import { mapGetters } from "vuex";

export default {
  name: "sbs-network-error",
  components: {
    okrainaIllustration
  },
  data() {
    return {
      open: false,
      loading: false,
      storedRegions: [],
      imageNetwork: imageNetworkPath
    };
  },

  computed: {
    ...mapGetters({
      regions: "getRegions",
      connection: "getConnection"
    }),

    innerRegions() {
      if (this.regions.length > 0) {
        return this.regions;
      }

      if (this.storedRegions.length > 0) {
        return this.storedRegions;
      }

      return false;
    }

  },


  methods: {
    /**
     * Показывает экран ошибки
     */
    show: function () {
      this.open = true;
    },

    /**
     * Обработка клика по кнопке "Обновить"
     */
    onRefreshClick: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;

      //тестируем соединение
      rest
        .isOnline()
        .then((success) => {
          if (success) {
            //если соединение появилось - закрываем экран
            this.open = false;
            store.commit("setOnline", true);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * Загружает сохранённый список регионо вна устройстве
     */
    async restoreRegions() {
      let regions = await storage.get("regions");
      if (regions) {
        this.storedRegions = regions;
      }
    }
  },

  created() {
    this.restoreRegions();
  },


  mounted() {
    //подписываемся на событие ошибки сети
    bus.on("OKRAINA_NETWORK_ERROR_E_SHOW", this.show);
  },
  unmounted() {
    //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
    bus.off("OKRAINA_NETWORK_ERROR_E_SHOW", this.show);
  },
};
</script>

<style lang="scss">
.okraina-network-error {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2000;

  display: none;

  backdrop-filter: blur(15px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    background: var(--color-view-background);
    opacity: 0.85;
  }

  .inner {
    position: absolute;
    top: 50%;
    right: 0px;
    left: 0px;
    z-index: 2;

    transform: translateY(-50%);
    .contacts-regions{
      a.region:first-child{
        margin-top:0px;
      }
      a.region{
        margin-top:15px;
      }

    }

  }

  &.open {
    display: block;
  }


}
</style>