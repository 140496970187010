<template>
    <div class="okraina-register-sms">

        <div class="title">Введите код из СМС</div>

        <okraina-control-input :required="true" :type="'text'" v-model="codeFromSMS" v-model:error="errors"
            v-model:valid="valid" />

        <okraina-control-input v-if="useCaptcha" v-model="captchaWord" type="text" label="Слово на картинке"
            :captcha="captchaURL" :required="true" v-model:error="captchaError" v-model:valid="captchaValid" />

        <div class="btn-wrap">
            <div class="btn btn-block btn1" :class="{ 'wait': saving }" v-ripple="'rgba(255, 255, 255, 0.15)'"
                @click="authorize">
                Подтвердить
                <div v-if="saving" class="btn-loader"></div>
            </div>
        </div>

    </div>
</template>

<script>
import okrainaControlInput from "@/components/controls/input.vue";
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";
export default {
    name: 'okraina-register-sms',
    components: {
        okrainaControlInput,
    },
    emits: ['onSuccess'],
    data() {
        return {
            codeFromSMS: "",
            captchaWord: "",
            captchaSid: "",
            captchaURL: "",
            captchaValid: false,
            captchaError: false,
            useCaptcha: false,
            saving: false,
            errors: false,
            valid: false,
        }
    },
    props: ['login'],
    computed: {

        ...mapGetters({
            isAuthorized: "isAuthorized",
        }),

    },
    methods: {
        /**
         * Авторизация
         */
        authorize() {

            if (this.saving) {
                return;
            }

            //проверка введённых данных
            if (!this.checkValues()) {
                return;
            }

            this.saving = true;

            let dataAuth = Object.assign({
                CAPTCHA_WORD: this.captchaWord,
                CAPTCHA_SID: this.captchaSid,
                LOGIN: this.login,
                PASSWORD: this.codeFromSMS,
            });

            this.saving = true;

            //авторизация через смс
            rest
                .call("auth.login", {
                    method: "post",
                    data: dataAuth,
                })
                .then((data) => {
                    //если ошибка входа
                    if (!data.SUCCESS) {

                        //если требуется капча
                        if (data.CAPTCHA_SID) {
                            this.useCaptcha = true;
                            this.captchaSid = data.CAPTCHA_SID;
                            this.captchaURL = data.CAPTCHA_URL;
                            this.captchaWord = "";
                        }

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

                    } else {
                        this.$emit('onSuccess');
                    }
                })
                .finally(() => {

                    this.saving = false;

                });
        },

        /**
        * Проверяет значения
        */
        checkValues(showErrors = true) {

            let error = !this.valid;
            //если нужно показать ошибки или если у поля нет ошибок
            if (showErrors || !error) {
                this.errors = error;
            }

            if (this.useCaptcha) {
                error = !this.captchaValid;
                if (showErrors || !error) {
                    this.captchaError = error;
                }
            }

            if (this.errors) {
                return false
            }

            if (this.captchaError) {
                return false;
            }

            return true;
        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            if (this.isAuthorized) {
                return false;
            }
            return {
                "component": this.$options.name,
                "data": {
                    codeFromSMS: this.codeFromSMS,
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;

            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }

    },

    created() {
        this.restoreData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

}
</script>

<style lang="scss">
.okraina-register-sms {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    text-align: center;

    .btn-wrap {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text-title);
        margin-bottom: 20px;
    }

}
</style>