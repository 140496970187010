<template>
    <div class="okraina-cart-item" :class="{ na: item.FIELDS.CAN_BUY != 'Y' }">


        <swiper slidesPerView="auto" :loop="false" :spaceBetween="0" :autoHeight="false" :resistance="false"
            :touchReleaseOnEdges="true" :longSwipes="false" class="mySwiper">


            <swiper-slide class="slide-item">
                <div class="item-inner">
                    <div class="fav" v-if="!isGift">
                        <okraina-catalog-product-favorite-btn v-if="product" :productId="product.FIELDS.ID" />
                    </div>
                    <div class="cashback" v-if="!isGift">
                        <okraina-catalog-product-cashback v-if="product" :item="product" />
                    </div>
                    <div class="photo-wrap">
                        <okraina-catalog-product-photo :item="product" @click="goToProduct" />
                    </div>
                    <div class="right-cell">
                        <div class="name" @click="goToProduct">
                            {{ item.FIELDS.NAME }}
                        </div>
                        <div class="props" v-if="displayProps.length > 0">
                            <div class="prop" v-for="prop in displayProps" :key="prop.code">
                                <span class="label">{{ prop.name }}:</span>
                                <span class="value">{{ prop.value }}</span>
                            </div>
                        </div>
                        <div class="price-basket">
                            <div>
                                <template v-if="!isGift">
                                    <okraina-catalog-product-price :item="item" v-if="item.PRICE" :showMeasure="false"
                                        :quantity="basketItem ? basketItem.quantity : 1" />
                                </template>
                                <template v-else>
                                    <div class="gift">В подарок</div>
                                </template>

                            </div>
                            <div class="basket">
                                <template v-if="product && !isGift && item.FIELDS.CAN_BUY == 'Y'">
                                    <okraina-catalog-product-buy-btn :productId="basketData.productId"
                                        :params="basketData.params" :checkQuantity="checkQuantity"
                                        :maxQuantity="maxQuantity" :maxQuantityMessage="maxQuantityMessage"
                                        :cartMode="true" />
                                </template>
                                <template v-else>
                                    <div :class="{ gift: isGift }">{{ parseInt(item.FIELDS.QUANTITY) }} шт</div>
                                </template>
                            </div>
                        </div>
                        <div class="cashback-value" v-if="cashbackValue">
                            Будет начислено {{ cashbackValue }} <span class="icon fi fi-money"></span>
                        </div>    
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-right" v-if="!isGift">
                <div class="action" @click="remove">
                    <span class="icon fi fi-close"></span>
                    <div class="text">Удалить</div>
                </div>
            </swiper-slide>

        </swiper>



    </div>
</template>

<script>
import okrainaCatalogProductPhoto from "@/components/catalog/product.photo.vue";
import okrainaCatalogProductCashback from "@/components/catalog/product.cashback.vue";
import okrainaCatalogProductBuyBtn from "@/components/catalog/product.buy.btn.vue";
import okrainaCatalogProductPrice from "@/components/catalog/product.price.vue";
import okrainaCatalogProductFavoriteBtn from "@/components/catalog/product.favorite.btn.vue";

import bus from "@/plugins/bus";
import rest from "@/plugins/rest";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

export default {
    name: "okraina-cart-item",
    components: {
        okrainaCatalogProductPhoto,
        okrainaCatalogProductCashback,
        okrainaCatalogProductBuyBtn,
        okrainaCatalogProductPrice,
        Swiper,
        SwiperSlide,
        okrainaCatalogProductFavoriteBtn
    },
    emits: ["deleted"],
    data() {
        return {

        };
    },
    props: {
        item: {
            type: Object,
            default() {
                return null;
            },
        },
        product: {
            type: Object,
            default() {
                return null;
            },
        },

    },
    computed: {
        /**
         * Сумма кешбэка
         */
        cashbackValue() {

            if(this.item.CASHBACK && this.item.CASHBACK.SUM){
                return this.item.CASHBACK.SUM;
            }

            return false;
        },

        /**
         * Флаг если ли позиция в текущем состоянии
         */
        inBasket() {
            return this.$store.getters.InBasket(this.item.FIELDS.PRODUCT_ID, this.item.PARAMS);
        },

        /**
         * Привязка к позиции в текущем состоянии
         */
        basketItem() {
            return this.$store.getters.getProductInBasket(
                this.item.FIELDS.PRODUCT_ID,
                this.item.PARAMS
            );
        },

        /**
         * Данные для добавления в корзину
         */
        basketData() {
            return {
                productId: this.item.FIELDS.PRODUCT_ID,
                params: this.item.PARAMS
            };
        },

        /**
         * Ограничивать кол-во
         */
        checkQuantity() {
            return this.product ? this.product.CATALOG.CHECK_QUANTITY : "Y";
        },

        /**
         * Максимальное кол-во
         */
        maxQuantity() {
            if (this.product) {
                return this.product.CATALOG.QUANTITY > 0
                    ? parseInt(this.product.CATALOG.QUANTITY)
                    : 0;
            }
            return parseInt(this.item.FIELDS.QUANTITY);
        },

        /**
         * Сообщение об ограничении кол-ва
         */
        maxQuantityMessage() {
            if (this.product) {
                return this.product.CATALOG.MAX_QUANTITY_MESSAGE
                    ? this.product.CATALOG.MAX_QUANTITY_MESSAGE
                    : "";
            }

            return "";
        },

        /**
         * Свойства для отображения
         */
        displayProps() {
            let props = [];

            let p = this.item.PARAMS.find(p => p.CODE == "RIFLING");
            if (p) {
                props.push({ code: "RIFLING", name: "Нарезка", value: p.VALUE });
            }

            return props;
        },

        /**
         * Определяет является ли товар подарком
         */
        isGift() {
            let p = this.item.PARAMS.find(p => p.CODE == "GIFT");
            if (p) {
                return true;
            }
            return false
        }
    },
    watch: {
        inBasket: function (newVal) {
            if (!newVal) {
                this.$emit("deleted", this.item.FIELDS.ID);
            }
        }
    },
    methods: {
        goToProduct() {
            if (this.product && this.product.FIELDS.CODE && !this.isGift) {
                bus.emit("OKRAINA_PRODUCT_MODAL_E_OPEN", {
                    id: this.product.FIELDS.ID,
                    code: this.product.FIELDS.CODE
                });
            }
        },

        /**
         * Удаление позиции
         */
        remove() {

            rest
                .call("basket.delete", {
                    method: "post",
                    data: {
                        PRODUCT_ID: this.item.FIELDS.PRODUCT_ID,
                        QUANTITY: 0,
                        PARAMS: this.item.PARAMS,
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        //изменяем кол-во в корзине
                        this.$store.commit("updateBasketItem", {
                            pid: this.item.FIELDS.PRODUCT_ID,
                            params: this.item.PARAMS,
                            quantity: 0
                        });

                        this.$emit("deleted", this.item.FIELDS.ID);
                    }

                    //если ошибька
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                });


        }
    },
}
</script>

<style lang="scss">
.okraina-cart-item {

    margin-bottom: 4px;
    margin-left: -8px;
    margin-right: -8px;
    
    .cashback-value{
        font-size: 11px;
        color:var(--color-brand-dark);
        font-weight:bold;
    }
    .swiper {
        padding-top: 4px;
    }

    .swiper-wrapper {
        height: auto;
        align-items: stretch;
    }

    .slide-item {
        height: auto;
        padding: 0px 8px;
    }

    .slide-right {
        width: 100px;
        height: auto;
        background-color: var(--color-btn1-background);
        color: var(--color-btn1-text);

    }

    .item-inner {
        position: relative;
        background-color: var(--color-block-background);
        border-radius: 5px;
        display: grid;
        grid-template-columns: 110px 1fr;
        align-items: center;
    }

    .cashback {
        position: absolute;
        top: -2px;
        left: 0px;
        z-index: 2;
    }

    .fav {
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 2;
    }

    .right-cell {
        padding: 8px;
    }

    .photo-wrap {
        border-radius: 5px 0px 0px 5px;
        overflow: hidden;
        padding: 10px 0px;
        position: relative;
    }

    .name {
        font-size: 12px;
        font-weight: 500;

        color: var(--color-text-dark);
    }

    .props {
        margin-top: 10px;

        .prop {
            margin-top: 5px;
            display: inline-block;
            font-size: 11px;
            font-weight: 500;

            .label {
                color: var(--color-text-title);

                &:after {
                    content: " ";
                }
            }

            .value {
                font-weight: 500;
                ;
            }
        }
    }

    .price-basket {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 75px;
        align-items: center;
    }

    .basket {
        text-align: right;
    }

    .action {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .text {
            margin-top: 5px;
        }
    }

    .gift {
        font-size: 14px;
        font-weight: 600;
        color: var(--color-color1);
    }


    /* товар недоступен */
    &.na {
        .photo-wrap {
            opacity: 0.5;
        }
    }



    /**
        Анимация
    */
    &.fade-move,
    &.fade-enter-active,
    &.fade-leave-active {
        transition: all 0.3s ease;
    }

    &.fade-enter-from,
    &.fade-leave-to {
        opacity: 0;
        transform: translateX(-50px);
    }

    &.fade-leave-active {
        position: absolute;
    }

}
</style>