<template>
    <div class="okraina-cart-counter">
        <b>{{ count }}</b> - доступно для доставки ({{ weight }}кг)
    </div>
</template>


<script>
import tools from "@/plugins/tools";

export default {
    name: "okraina-cart-counter",
    data() {
        return {
        }
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        products: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {

        count() {

            let value = this.items.reduce(function (sum, item) {
                return sum + parseInt(item.FIELDS.QUANTITY);
            }, 0);

            let str = value + " " + tools.countWord(value, ['товар', 'товара', 'товаров']);

            return str;
        },

        weight() {

            let vm = this;
            let value = this.items.reduce(function (sum, item) {

                let product = vm.products.find(p => p.FIELDS.ID == item.FIELDS.PRODUCT_ID);
                if (product && product.PROPS && product.PROPS.VES) {
                    return sum + parseInt(item.FIELDS.QUANTITY) * parseFloat(product.PROPS.VES);
                }
                return sum;
            }, 0);

            return Math.round(value * 100) / 100
        }

    }
}

</script>

<style lang="scss">
.okraina-cart-counter {
    font-size: 12px;
    margin-bottom: 7px;
    padding: 0px 8px;
}
</style>