<template>
    <div class="okraina-control-suggestions" :class="{ show: show }">

        <div class="select-mess">Выберите вариант или продолжите ввод</div>

        <div class="item" v-for="item in items" :key="item.value" @click="onItemClick(item)">
            {{ getDisplayAddress(item) }}
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
    name: "okraina-control-suggestions",
    emits: ["update:modelValue"],
    data() {
        return {
            items: [],
            show: false
        };
    },

    props: {

        modelValue: {
            type: [String, Number],
            default() {
                return "";
            },
        },
        focused: {
            type: Boolean,
            default() {
                return false;
            }
        },
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        apiKey() {
            return this.siteSettings ? this.siteSettings.DADATA_API_KEY : ""
        },
        locations() {
            return this.siteSettings ? this.siteSettings.DADATA_SUGGESTIONS_LOCATIONS : ""
        }
    },

    watch: {
        modelValue: function () {
            if (this.focused) {
                this.search();
            }
        },
        focused: function () {
            if (!this.focused) {
                setTimeout(() => {
                    this.show = false
                })
            } else {
                this.search();
            }
        }
    },
    methods: {
        search() {

            axios({
                url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
                timeout: 1000,
                method: "POST",
                data: {
                    query: this.modelValue,
                    count: 3,
                    locations: this.locations
                },
                headers: {
                    "Content-Type": " application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + this.apiKey
                }
            }).then((response) => {
                if (response && response.data && response.data.suggestions && response.data.suggestions.length > 0) {
                    this.items = response.data.suggestions;
                    this.show = true;
                } else {
                    this.items = [];
                    this.show = false
                }
            })
        },

        getDisplayAddress(item) {
            let parts = [];
            if (item.data.city_with_type) {
                parts.push(item.data.city_with_type);
            }

            if (item.data.street_with_type) {
                parts.push(item.data.street_with_type);
            }
            if (item.data.settlement_with_type) {
                parts.push(item.data.settlement_with_type);
            }
            if (item.data.house) {
                parts.push(item.data.house);
            }

            if (parts.length == 0) {
                parts.push(item.data.region_with_type);
            }

            let address = parts.join(", ");
            return address;
        },

        onItemClick(item) {

            let address = this.getDisplayAddress(item);
            this.$emit("update:modelValue", address);

            this.show = false;
        }
    }
}

</script>

<style lang="scss">
.okraina-control-suggestions {
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    z-index: 500;

    box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.1);
    background-color: var(--color-block-background);

    padding: 7px 12px;
    border: 1px solid var(--color-control-border);

    display: none;

    &.show {
        display: block;
    }

    .select-mess {
        margin-bottom: 10px;
        font-size: 11px;
        color: var(--color-control-description);
    }

    .item {
        padding: 10px 0px;
        font-size: 12px;
    }
}
</style>