<template>
    <div class="okraina-smbs">

        <swiper :modules="modules" :slidesPerView="3.3" :loop="false" :cssMode="false" :spaceBetween="8">
            <swiper-slide v-for="item in items" :key="item.FIELDS.ID">
                <div class="item" v-ripple @click="onItemClickDelayed(item)">
                    <div class="image preloaded" v-if="item.FIELDS.PREVIEW_PICTURE"
                        v-lazy:background-image="item.FIELDS.PREVIEW_PICTURE.SRC">
                        <div class="loader">
                            <okraina-loader-inline />
                        </div>

                    </div>

                </div>
            </swiper-slide>
        </swiper>

    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

import tools from "@/plugins/tools";

import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";

export default {
    name: "okraina-sliders-main-banners-static",
    components: {
        Swiper,
        SwiperSlide,
        okrainaLoaderInline
    },
    data() {
        return {
            modules: [Pagination],
        };
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    methods: {

        /**
         * Клик по баннеру с откладываение исполнения
         */
        onItemClickDelayed(item) {
            setTimeout(() => {
                this.onItemClick(item);
            }, 400);
        },

        /**
         * Клик по банеру
         */
        onItemClick(item) {

            //парсим параметры (например переход к странице поиска)
            const [, paramString] = item.PROPS.URL.split('?');
            let urlParams = new URLSearchParams(paramString);
            let entries = urlParams.entries();
            let query = {};
            for (let [key, value] of entries) {
                query[key] = value;
            }

            //куда-то в каталог
            if (item.PROPS.URL.startsWith("/catalog")) {
                this.$router.push({ path: item.PROPS.URL, query: query });
            }
            //в акции
            else if (item.PROPS.URL.startsWith("/clients/actions")) {
                this.$router.push({ path: item.PROPS.URL, query: query });
            }
            //в новости
            else if (item.PROPS.URL.startsWith("/about/news")) {
                this.$router.push({ path: item.PROPS.URL, query: query });
            }
            //какой-то сайт
            else if (item.PROPS.URL.startsWith("http")) {

                let color = tools.getCSSVariable('--color-background');
                this.paymentBrowser = InAppBrowser.create(item.PROPS.URL, "_system", {
                    location: "yes",
                    toolbarcolor: tools.convertHexColor(color)
                });
            }
            //куда-то ещё
            else {
                this.$router.push({ path: item.PROPS.URL });
            }


            //todo
        }
    }
};
</script>

<style lang="scss">
.okraina-smbs {
    margin-bottom: 8px;

    .swiper {
        margin: 0px calc(0px - var(--view-content-hor-padding));
        padding: 0px var(--view-content-hor-padding);
    }

    .item {
        .image {
            border-radius: 20px;
            background-size: cover;

            height: 0px;
            padding-top: 133%;

        }
    }
}
</style>
