<template>
    <okraina-view :class="'okraina-v-auth-register'" :refreshDisabled="true" :hideMenu="true"
        :contentHorizontalPadding="32">

        <template v-slot:header>
            <h1>
                Регистрация
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <!-- Регистрация -->
            <template v-if="step == 1">
                <okraina-register @onRegister="onRegister" />
            </template>

            <!-- Авторизация -->
            <template v-if="step == 2">
                <okraina-register-sms @onSuccess="onSuccess" :login="this.login" />
            </template>

            <!-- Успешно -->
            <template v-if="step == 3">

                <div class="success">

                    <div class="title">Спасибо за регистрацию!</div>

                    <div class="description" v-if="bonus > 0">
                        Вы получили +{{ bonus }}<br />
                        бонусов на свой счёт
                    </div>

                    <div class="bonus" v-if="bonus > 0">
                        <span class="value">+{{ bonus }}</span>
                        <span class="icon fi fi-money"></span>
                    </div>

                    <a class="btn btn1 btn-block" href="/catalog/" v-ripple
                        @click.prevent="goToCatalog('/catalog/')">Перейти в
                        каталог</a>
                </div>

            </template>

        </template>

    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue";
import okrainaRegister from "@/components/auth/register.vue";
import okrainaRegisterSms from "@/components/auth/register.sms.vue";

import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/auth.register.js";

export default {
    name: "okraina-v-auth-register",
    components: {
        okrainaView,
        okrainaRegister,
        okrainaRegisterSms,
    },
    data() {
        return {
            step: 1,
            login: "",
            bonus: 0,
        };
    },
    computed: {},
    methods: {


        /**
         * В случае удачной регистрации - переходим к вводу СМС (попутно запоминаем логин и кол-во приветственных бонусов)
         */
        onRegister(phone, countBonus) {

            if (phone) {
                this.login = phone;
            }

            if (countBonus) {
                this.bonus = countBonus;
            }

            this.step = 2;

        },

        /**
         * Обработка успешного входа
         */
        onSuccess() {

            //показываем сообщение об успехе
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Вы успешно зарегистрировались.", type: "success" });

            this.step = 3;

        },

        /**
         * Переход в каталог
         */
        goToCatalog() {

            let vm = this;
            setTimeout(() => {
                vm.$router.replace({ path: "/catalog/" });
            }, 500);

        },

        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            if (this.isAuthorized) {
                return false;
            }
            return {
                "component": this.$options.name,
                "data": {
                    step: this.step,
                    bonus: this.bonus,
                    login: this.login
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;

            let params = restoreData.find((p) => p.component == this.$options.name);

            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        },

    },

    created() {
        this.restoreData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);
    },

};
</script>

<style lang="scss">
.okraina-v-auth-register {
    .view-outer {
        background-color: var(--color-block-background);
    }

    .view-content {
        padding-top: 15px;
    }

    .success {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        text-align: center;

        .title {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text-title);
            margin-bottom: 20px;
        }

        .description {
            font-weight: 300;
            color: var(--color-text-description);
            margin-bottom: 20px;
        }

        .bonus {
            margin-bottom: 40px;
            font-size: 40px;

            .value {
                font-weight: bold;

            }

            .value,
            .icon {
                background: linear-gradient(281.01deg, #2793CA 24.43%, #399DAD 30.54%, #59AF79 42.64%, #74BE4D 54.53%, #88C92C 65.96%, #97D114 76.79%, #A0D605 86.8%, #A3D800 95.18%), #FFFFFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }
}
</style>