<template>
  <okraina-view :class="'okraina-v-catalog-section'" @refresh="refresh" ref="view" :noHeaderBorder="true">
    <template v-slot:header>
      <okraina-catalog-search-input />
    </template>
    <template v-slot:content>
      <transition>
        <okraina-loader-page v-show="loading" />
      </transition>

      <template v-if="this.section || this.specialMode">
        <div class="title">
          <h1>{{ title }}</h1>

          <div class="tools">

            <div>
              <div class="filter-btn" :class="{ filled: Object.keys(filter).length > 0 }" @click="showFilter">
                <span class="icon fi fi-filter"></span>
              </div>
            </div>
            <div>
              <div class="sort-btn" v-if="showSort">
                <okraina-catalog-sort-btn />
              </div>
            </div>

          </div>

        </div>

        <okraina-catalog-filter v-model="filter" v-model:visible="filterVisible" :preFilter="preFilter"
          :sectionId="section ? section.ID : ''" />

        <okraina-catalog-subsections v-if="subSections.length > 0" :items="subSections" :current="section" />

        <okraina-catalog-list v-model:loading="loading" :filter="resultFilter" :sort="sort"
          :refreshIndex="refreshIndex" />

      </template>
    </template>
  </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaCatalogSearchInput from "@/components/catalog/search.input.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaCatalogSubsections from "@/components/catalog/subsections.vue";
import okrainaCatalogList from "@/components/catalog/list.vue";
import okrainaCatalogSortBtn from "@/components/catalog/sort.btn.vue";
import okrainaCatalogFilter from "@/components/catalog/filter.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/catalog.section.js";
import metaTemplatesNew from "@/plugins/meta/catalog.section.new.js";
import metaTemplatesHits from "@/plugins/meta/catalog.section.hits.js";
import metaTemplatesDiscount from "@/plugins/meta/catalog.section.discount.js";
import metaTemplatesShare from "@/plugins/meta/catalog.section.share.js";
import metaTemplatesAll from "@/plugins/meta/catalog.section.all.js";

import { mapGetters } from "vuex";

export default {
  name: "okraina-v-catalog-section",
  components: {
    okrainaView,
    okrainaCatalogSearchInput,
    okrainaLoaderPage,
    okrainaCatalogSubsections,
    okrainaCatalogList,
    okrainaCatalogSortBtn,
    okrainaCatalogFilter
  },
  data() {
    return {
      loading: true,
      section: false,
      subSections: [],
      refreshIndex: 0,
      filter: {},
      filterVisible: false,
    };
  },
  props: {
    path: {
      type: Array,
      default() {
        return [];
      },
    },
    basketId: {
      type: [String, Number],
      default() {
        return 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentSort: "getCatalogSortCurrent",
    }),
    preFilter() {
      //товары по акциям
      if (this.discountMode) {
        return {
          "!PROPERTY_ACTION": false,
        };
      }
      //хиты продаж
      if (this.hitsMode) {
        return {
          "!PROPERTY_SALELEADER": false,
        };
      }
      //новинки
      if (this.newMode) {
        return {
          "!PROPERTY_NEWPRODUCT": false,
        };
      }
      if (this.shareMode) {
        return {
          "BASKET_ID": this.basketId
        }
      }
      //все товары
      if (this.allMode) {
        return {
        };
      }

      //обычный раздел
      return {
        SECTION_ID: this.section.ID,
        INCLUDE_SUBSECTIONS: "Y"
      };
    },

    /**
     * Результирующий фильтр
     */
    resultFilter() {
      return Object.assign({}, this.preFilter, this.filter)
    },

    sort() {
      let item = this.currentSort;
      if (!item) {
        return false;
      }

      let sort = {
        PROPERTY_AVAILABLE: "DESC"
      };
      sort[item.PROPS.BY] = item.PROPS.ORDER;
      sort.ID = "DESC";
      return sort;
    },
    specialMode() {
      return this.discountMode || this.hitsMode || this.newMode || this.shareMode || this.allMode;
    },
    discountMode() {
      return this.path.join("/") == "discount";
    },
    hitsMode() {
      return this.path.join("/") == "saleleader";
    },
    newMode() {
      return this.path.join("/") == "newproduct";
    },
    shareMode() {
      return this.path.join("/") == "sharebasket";
    },
    allMode() {
      return this.path.join("/") == "all";
    },
    title() {
      if (this.discountMode) {
        return "Скидки";
      }
      if (this.hitsMode) {
        return "Хиты продаж";
      }
      if (this.newMode) {
        return "Новинки";
      }
      if (this.allMode) {
        return "Все товары";
      }
      if (this.shareMode) {
        return "С Вами поделились корзиной";
      }

      if (this.section) {
        return this.section.NAME;
      }

      return "";
    },
    showSort() {
      return (
        this.specialMode || this.section
      );
    },
  },
  watch: {
    path: function (newVal, oldVal) {
      if (oldVal.join("/") != newVal.join("/")) {
        this.filter = {};
        if (!this.specialMode) {
          this.loadSection();
        } else {
          this.section = false;
        }
      }
    },
    section: function () {
      this.setMeta();
    }
  },
  methods: {
    /**
     * Загружает раздел по пути
     */
    loadSection(resolve) {
      //покажем экран загрузки
      this.loading = resolve ? false : true;
      if (!resolve) {
        this.section = false;
        this.subSections = [];
      }

      //запрашивает даные
      rest
        .call("catalog.sections.getbypath", {
          method: "post",
          data: {
            PATH: "/" + this.path.join("/") + "/",
          },
        })
        .then((data) => {
          this.refreshIndex = this.refreshIndex + 1;
          if (data.SUCCESS) {
            this.section = data;
            this.loadSubSections();
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
            //скроем экран загрузки
            this.loading = false;
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.loading = false;
          if (resolve) {
            setTimeout(resolve, 200);
          }
        });
    },

    /**
     * Подгрузка подразделов
     */
    loadSubSections() {

      let filter = {};
      //если есть подразделы
      if (this.section.SECTIONS_CNT > 0) {
        filter.SECTION_ID = this.section.ID;
      }
      //иначе если есть родительский раздел
      else if (this.section.IBLOCK_SECTION_ID > 0) {
        filter.SECTION_ID = this.section.IBLOCK_SECTION_ID;
      }
      //иначе разделы 1-го уровня 
      else {
        this.subSections = [];
        return;
      }

      rest
        .call("catalog.sections.getlist", {
          method: "post",
          data: {
            ORDER: {
              SORT: "ASC",
              NAME: "ASC",
            },
            FILTER: filter,
          },
        })
        .then((data) => {
          this.subSections = data.ITEMS;
        })
        .finally(() => {

        });
    },

    /**
     * Перезагрузка экрана
     */
    refresh(resolve) {
      if (!this.specialMode) {
        this.loadSection(resolve);
      } else {
        this.refreshIndex++;
        if (resolve) {
          setTimeout(resolve, 200);
        }
      }
    },

    /**
     * Устанавливает мета данные страницы
     */
    setMeta() {
      if (this.section) {
        meta.write(metaTemplates, this.section.META);
      } else if (this.newMode) {
        meta.write(metaTemplatesNew);
      } else if (this.hitsMode) {
        meta.write(metaTemplatesHits);
      } else if (this.discountMode) {
        meta.write(metaTemplatesDiscount);
      } else if (this.shareMode) {
        meta.write(metaTemplatesShare);
      } else if (this.allMode) {
        meta.write(metaTemplatesAll);
      }
    },

    /**
     * Показать фильтр
     */
    showFilter() {
      this.filterVisible = true;
    }
  },

  created() {
    if (!this.specialMode) {
      this.loadSection();
    }
  },

  /**
   * Вызывается перед переходом из этого экрана в другой
   */
  beforeRouteLeave() {
    this.$refs.view.onBeforeRouteLeave();
  },

  /**
   * Обраотка переход входом на экран
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setMeta();
      vm.$refs.view.onBeforeRouteEnter();
    });
  },

  mounted() {
    bus.on("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },

  beforeUnmount() {
    bus.off("OKRAINA_MENU_E_PRESSING_ITEM_AGAIN", this.$refs.view.scrollTop)
  },
};
</script>

<style lang="scss">
.okraina-v-catalog-section {
  .title {
    position: relative;
    padding-right: 75px;

    h1 {
      color: var(--color-color1);
    }

    .tools {
      position: absolute;
      top: 0px;
      right: 0px;

      display: flex;
      align-items: center;
      column-gap: 10px;

      .filter-btn {
        color: var(--color-color1);
        font-size: 18px;
        line-height: 0px;

        width: 28px;
        height: 28px;
        border-radius: 50%;
        position: relative;

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.filled {
          background-color: var(--color-btn1-background);
          color: var(--color-btn1-text);
        }

      }

    }


  }
}
</style>
