<template>
  <div class="okraina-csb">
    <div class="icon-btn" @click="open">
      <span class="fi fi-sort"></span>
    </div>
  </div>

  <okraina-sheet v-model:visible="isOpen" class="okraina-csb-sorts-wrap">
    <template v-slot:body>
      <div class="okraina-csb-sorts">

        <div class="item" v-for="item in sorts" :key="item.FIELDS.ID" :class="{ current: value == item.FIELDS.ID }"
          @click="onItemClick(item)">
          {{ item.FIELDS.NAME }}

          <div class="check fi fi-check" v-if="value == item.FIELDS.ID">
          </div>
        </div>

      </div>
    </template>

  </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";

import { mapGetters } from "vuex";

export default {
  name: "okraina-catalog-sort-btn",
  components: {
    okrainaSheet

  },
  data() {
    return {
      isOpen: false,
      //устанавливаем по-умолчанию в created(), так как на момент создания data() св-ва в computed ещё не сформированы
      value: false,
    };
  },
  emits: ["update:modelValue"],
  props: {
    sort: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      type: [Number, String, Boolean],
      default() {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters({
      sorts: "getCatalogSorts",
      currentSort: "getCatalogSortCurrent",
    }),
  },
  watch: {
    modelValue: function (newVal) {
      this.value = newVal;
    },
    value: function (newVal, oldVal) {
      if (oldVal != false) {
        this.save(newVal);
        this.close();
      }
    },
    currentSort: function (newVal) {
      this.value = newVal.FIELDS.ID;
    },
  },

  methods: {

    /**
     * Открыть
     */
    open() {
      this.isOpen = true;
    },

    /**
     * Закрыть
     */
    close() {
      this.isOpen = false;
    },

    /**
     * Клик по варианту сортировки
     */
    onItemClick(item) {
      this.value = item.FIELDS.ID;
      this.close();
    },

    /**
     * Сохраняет выбранный вариант сортировки в подключении
     */
    save(newVal) {
      //сохраняем выбранную сортировку в текущем состоянии
      let arConnection = this.$store.getters.getConnection;
      arConnection.data["catalogSortId"] = newVal;
      this.$store.commit("updateConnection", arConnection);
    },
  },

  created() {
    this.value = this.currentSort ? this.currentSort.FIELDS.ID : false;
  },
};
</script>

<style lang="scss">
.okraina-csb {

  .icon-btn {
    color: var(--color-color1);
    font-size: 18px;
    line-height: 0px;
    padding: 5px;
  }

}

.okraina-csb-sorts-wrap {
  .sheet-body {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.okraina-csb-sorts {
  .item {
    position: relative;
    padding: 12px 16px;
    font-weight: 500;

    &.current {
      background-color: var(--color-color6);
    }

    .check {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 18px;
      color: var(--color-color1);
    }
  }
}
</style>
