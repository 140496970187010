<template>
    <div class="okraina-pbh" ref="root">

        <!-- Загрузка изначальная -->
        <template v-if="loading">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>

        <template v-if="!loading">

            <div class="nearest-expire" v-if="nearestExpire">
                <div>Успейте потратить бонусы</div>
                <div class="sum">{{ nearestExpire.SUM / 100 }} из {{ balance }}</div>
                <div>баллов сгорят уже {{ nearestExpire.DATE }}</div>
            </div>

            <h2>История</h2>

            <div v-if="items.length == 0">
                Записей в истории ещё нет.
            </div>

            <div class="items">

                <div class="item" v-for="(item, index) in items" :key="index">
                    <div class="date">
                        <div></div>
                        <div>{{ item.DATE }}</div>
                        <div></div>
                    </div>
                    <div class="inner">
                        <div class="notes">{{ item.NOTES }}</div>
                        <div class="sum" :class="{ debit: item.DEBIT == 'Y' }">
                            {{ item.DEBIT == "Y" ? "+" : "-" }}
                            {{ Math.round(item.SUM * 100) / 100 }}
                        </div>
                    </div>

                </div>

            </div>

        </template>



        <div ref="pager" class="pager"></div>

        <!-- Подгрузка следюущей страницы -->
        <template v-if="loadingPaging">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>

        <!-- Ошибка загрузки -->
        <template v-if="loadingError && !loadingCommon">

            <div class="text-center">
                Ошибка загрузки данных.
                <br /><br />
                <div class="btn btn1" @click="retryLoad">Повторить</div>
            </div>

        </template>


    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "okraina-personal-bonus-history",
    components: {
        okrainaLoaderInline
    },
    data() {
        return {
            loading: false,
            loadingPaging: false,
            loadingCommon: false,
            loadingError: false,
            items: [],
            nearestExpire: false,
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 15,
                PAGES_COUNT: 1,
            },
        };
    },

    computed: {
        ...mapGetters({
            balance: "getBonus",
            cashback: "getCashback"
        }),
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData() {

            if (this.loadingCommon) {
                return;
            }
            this.loadingCommon = true;

            rest
                .call("bonus.transactions", {
                    method: "post",
                    data: {

                        NAV_PARAMS: this.pager,
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                        this.nearestExpire = data.NEAREST_EXPIRE_BONUS;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;

                    }
                }).finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    this.loadingCommon = false;
                });
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadingPaging = true;
            this.loadData();
        },

        /**
       * Скрол экрана
       */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;

            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingCommon &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData();
                }
            }
        },

    },

    created() {
        this.loading = true;
        this.loadData();
    },


    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .addEventListener("scroll", this.onScroll);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.pager
            .closest(".view-content-scroll")
            .removeEventListener("scroll", this.onScroll);
    },
}

</script>

<style lang="scss">
.okraina-pbh {

    padding-bottom: 50px;

    .nearest-expire {
        margin-bottom: 30px;
        border-radius: 10px;
        border: 2px solid var(--color-color2);
        padding: 15px 25px;
        text-align: center;
        font-size: 16px;
        color: var(--color-color1);
        font-weight: 500;

        .sum {
            font-size: 20px;
        }
    }

    .item {
        border-bottom: 1px solid var(--color-bonus-history-border);
        margin-bottom: 25px;
        padding-bottom: 10px;

        .date {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            column-gap: 5px;
            align-items: center;

            >div:first-of-type,
            >div:last-of-type {
                height: 1px;
                background-color: var(--color-bonus-history-border);
            }
        }

        .inner {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 15px;
            padding: 5px 0px;
        }

        .notes {
            color: var(--color-bonus-desc);
        }

        .sum {
            color: var(--color-bonus-history-credit);
            font-weight: bold;
            white-space: nowrap;


            &.debit {
                color: var(--color-bonus-history-debit);
            }
        }
    }
}
</style>