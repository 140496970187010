<template>
    <div class="okraina-cp-rl" ref="root">

        <!-- Загрузка изначальная -->
        <template v-if="loading">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>

        <div class="items" v-if="!loading">
            <okraina-catalog-product-review-item v-for="item in items" :key="item.FIELDS.ID" :item="item"
                @complaint="onComplaint" @onAuthClick="onAuthClick" />
        </div>
        <div ref="pager" class="pager"></div>

        <!-- Подгрузка следюущей страницы -->
        <template v-if="loadingPaging">
            <div class="text-center">
                <okraina-loader-inline />
            </div>
        </template>


        <!-- Ошибка загрузки -->
        <template v-if="loadingError && !loadingPaging">

            <div class="text-center">
                Ошибка загрузки данных.
                <br /><br />
                <div class="btn btn1" @click="retryLoad">Повторить</div>
            </div>

        </template>

        <okraina-sheet v-model:visible="showComplaint" :maxHeight="90">
            <template v-slot:body>
                <okraina-web-form :id="complaintWebFormId" :sheetMode="true" :extValues="complaintHiddenValues" />
            </template>
        </okraina-sheet>
    </div>
</template>

<script>
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaCatalogProductReviewItem from "@/components/catalog/product.review.item.vue";
import okrainaSheet from "@/components/sheet.vue"
import okrainaWebForm from "@/components/web.form.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import { mapGetters } from "vuex";

export default {
    name: "okraina-catalog-product-review-list",
    components: {
        okrainaLoaderInline,
        okrainaCatalogProductReviewItem,
        okrainaSheet,
        okrainaWebForm
    },
    emits: ["onAuthClick"],
    data() {
        return {
            loading: false,
            loadingPaging: false,
            loadingCommon: false,
            loadingError: false,
            items: [],
            pager: {
                NUM_PAGE: 1,
                PAGE_SIZE: 15,
                PAGES_COUNT: 1,
            },
            showComplaint: false,
            complaintHiddenValues: {}
        };
    },
    props: {
        productId: {
            type: [Number, String],
            default() {
                return 0;
            },
        },

    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        complaintWebFormId() {
            return this.siteSettings ? this.siteSettings.FORM_PRODUCT_REVIEWS_COMPLAINTS : "";
        }
    },

    methods: {

        /**
         * Загрузка данных
         */
        loadData() {

            if (this.loadingCommon) {
                return;
            }
            this.loadingCommon = true;

            rest
                .call("reviews.getlist", {
                    method: "post",
                    data: {
                        PRODUCT_ID: this.productId,
                        NAV_PARAMS: this.pager,
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {
                        if (this.pager.NUM_PAGE == 1) {
                            this.items = data.ITEMS;
                        } else {
                            this.items = this.items.concat(data.ITEMS);
                        }
                        this.pager = data.PAGER;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;

                    }
                }).finally(() => {
                    this.loading = false;
                    this.loadingPaging = false;
                    this.loadingCommon = false;
                });
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadingPaging = true;
            this.loadData();
        },

        /**
         * Скрол экрана
         */
        onScroll(e) {
            let scroll = e.target.scrollTop;
            let height = e.target.offsetHeight;
            let offsetTop = this.$refs.pager.offsetTop;

            //если экран прокрутился до пейджера
            if (scroll + height > offsetTop) {
                //если экран не в стадии загрузки и есть следующие страницы
                if (
                    !this.loadingCommon &&
                    this.pager &&
                    this.pager.NUM_PAGE < this.pager.PAGES_COUNT
                ) {
                    this.pager.NUM_PAGE++;
                    this.loadingPaging = true;
                    this.loadData();
                }
            }
        },

        /**
         * Обработка события вызова формы жалобы
         */
        onComplaint(data) {
            this.showComplaint = true;
            this.complaintHiddenValues = data;
        },

        /**
         * Обработка события необходимости обновить данные
         */
        onRefresh() {
            this.loading = true;
            this.pager.NUM_PAGE = 1;
            this.loadData();
        },

        /**
         * Обработка перехода к авторизации
         */
        onAuthClick() {
            this.$emit("onAuthClick");
        }


    },

    created() {
        this.loading = true;
        this.loadData();
    },

    mounted() {
        //подписываемся на скролинг экрана
        this.$refs.root
            .closest(".sheet-body")
            .addEventListener("scroll", this.onScroll);

        //подписваемся на события
        bus.on("OKRAINA_PRODUCT_REVIEW_E_BLOCK", this.onRefresh);
    },

    beforeUnmount() {
        //отписываесмся от скролинга экрана
        this.$refs.root
            .closest(".sheet-body")
            .removeEventListener("scroll", this.onScroll);
    },

    unmounted() {
        //обязательно отписываемся, а то в случае чего в памяти подписка сохраняется
        bus.off("OKRAINA_PRODUCT_REVIEW_E_BLOCK", this.onRefresh);
    },
}

</script>

<style lang="scss">
.okraina-cp-rl {
    padding-bottom: 20px;
}
</style>