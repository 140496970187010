<template>
  <div class="okraina-cs">
    <div class="items">
      <div class="item" v-for="item in itemsExt" :key="item.ID" @click="onClick(item)" v-ripple>
        <div class="picture"
          :class="{ empty: !item.UF_ICON, discount: item.ID == 'discount', actions: item.ID == 'actions' }">
          <img v-if="item.UF_ICON" :src="item.UF_ICON.SRC" />
        </div>
        <div class="name">
          {{ item.NAME }}
        </div>
        <div class="arrow">
          <span class="fi fi-arrow-right"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

import imageDiscountPath from "@/assets/images/catalog/discount.svg";
import imageNewPath from "@/assets/images/catalog/new.svg";
import imageHitPath from "@/assets/images/catalog/hit.svg";
import imageActionsPath from "@/assets/images/catalog/actions.svg";

export default {
  name: "okraina-catalog-sections",
  components: {},
  emits: ["update:loading"],
  data() {
    return {
      items: [],
    };
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    filter: {
      type: Object,
      default() {
        return {};
      },
    },
    refreshIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  computed: {
    itemsExt() {
      //берём копию
      let items = this.items.slice();

      //Хиты
      items.unshift({
        ID: "saleleader",
        NAME: "Хиты продаж",
        SECTION_PAGE_URL: "/catalog/saleleader/",
        UF_ICON: {
          SRC: imageHitPath
        }
      });

      //Новинки
      items.unshift({
        ID: "newproduct",
        NAME: "Новинки",
        SECTION_PAGE_URL: "/catalog/newproduct/",
        UF_ICON: {
          SRC: imageNewPath
        }
      });

      //скидки
      items.unshift({
        ID: "discount",
        NAME: "Скидки",
        SECTION_PAGE_URL: "/catalog/discount/",
        UF_ICON: {
          SRC: imageDiscountPath
        }
      });

      //Акции
      items.unshift({
        ID: "actions",
        NAME: "Акции",
        SECTION_PAGE_URL: "/clients/actions/",
        UF_ICON: {
          SRC: imageActionsPath
        }
      });

      return items;
    }
  },
  watch: {
    refreshIndex: function () {
      this.loadData(false);
    },
  },
  methods: {
    /**
     * Загружает данные
     */
    loadData(loading = true) {
      if (loading) {
        //покажем экран загрузки
        this.$emit("update:loading", true);
      }

      //запрашивает даные
      rest
        .call("catalog.sections.getlist", {
          method: "post",
          data: {
            ORDER: {
              NAME: "ASC",
            },
            FILTER: this.filter,
            RESIZE_OPTIONS: {
              WIDTH: "100",
              HEIGHT: "100",
              TYPE: "EXACT",
            },
          },
        })
        .then((data) => {
          if (data.SUCCESS) {
            this.items = data.ITEMS;
          } else {
            //показываем сообщение об ошибке
            bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
          }
        })
        .finally(() => {
          //скроем экран загрузки
          this.$emit("update:loading", false);
        });
    },

    /**
     * Клик по разделу
     */
    onClick(item) {
      setTimeout(() => {
        this.$router.push(item.SECTION_PAGE_URL);
      }, 400);

    },
  },

  created() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.okraina-cs {
  .item {
    background-color: var(--color-block-background);
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
    align-items: center;
    margin-bottom: 2px;
    border-radius: 5px;

    padding: 10px;
    min-height: 50px;



    .picture {
      width: 43px;
      padding-right: 15px;
      padding-left: 5px;

      &.empty {
        width: 0px;
      }

      img {
        width: 100%;
      }

      &.discount {
        img {
          width: 70%;
        }
      }

      &.actions {
        img {
          width: 90%;
        }
      }
    }

    .name {

      color: var(--color-text-dark);
      font-weight: 500;
    }

    .arrow {
      padding-left: 15px;
      color: var(--color-text-gray);
    }
  }
}
</style>
