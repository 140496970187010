<template>
    <div class="okraina-cart-coupons">
        <div class="panel" @click="edit">
            <span class="icon-sale fi fi-sale"></span>
            <span class="text">
                <span class="label" v-if="items.length > 0">промокод</span>
                <template v-if="items.length == 0">Промокод или сертификат</template>
                <template v-else>{{ firstCoupon }}</template>

            </span>
            <span class="icon-arrow fi" :class="[items.length == 0 ? 'fi-arrow-right' : 'fi-pencil']"></span>
        </div>

    </div>

    <okraina-sheet v-model:visible="popupVisible">
        <template v-slot:body>
            <div class="okraina-cart-coupons-modal">

                <div v-if="items.length > 0" class="items">
                    <div class="label">Уже введённые промокоды</div>
                    <div class="item" v-for="item in items" :key="item.COUPON"
                        :class="{ 'not-applyed': item.STATUS != 'APPLYED' }">
                        <span class="coupon">{{ item.COUPON }}</span>

                        <span class="status">({{ item.STATUS_TEXT }})</span>

                        <div class="delete" @click="remove(item.COUPON)"><span class="icon fi fi-delete"></span></div>
                    </div>
                </div>

                <okraina-control-input label="Новый промокод" v-model="coupon"
                    :placeholder="'код промокода или сертификата'" />

                <div class="btn btn-block btn1" @click="apply" v-ripple :class="applying ? 'wait' : ''">
                    Применить
                    <div v-if="applying" class="btn-loader"></div>
                </div>
            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";
import okrainaControlInput from "@/components/controls/input.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";

export default {
    name: "okraina-cart-coupons",
    components: {
        okrainaSheet,
        okrainaControlInput
    },
    emits: ["updated"],
    data() {
        return {
            popupVisible: false,
            coupon: "",
            applying: false
        }
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    computed: {
        firstCoupon() {
            let first = this.items.find(item => item != false);
            return first.COUPON;
        }
    },

    methods: {

        /**
         * Открыть редактирование купонов
         */
        edit() {
            this.coupon = "";
            this.popupVisible = true;
        },

        /**
         * Применить купон
         */
        apply() {
            if (!this.coupon || this.applying) {
                return;
            }

            this.applying = true;

            rest
                .call("coupon.add", {
                    method: "post",
                    data: {
                        COUPON: this.coupon
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {

                        this.popupVisible = false;
                        this.$emit("updated");

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

                    }
                })
                .finally(() => {
                    this.applying = false;
                });


        },

        /**
         * Удаление купона
         */
        remove(coupon) {

            rest
                .call("coupon.delete", {
                    method: "post",
                    data: {
                        COUPON: coupon
                    },
                })
                .then((data) => {

                    if (data.SUCCESS) {

                        this.popupVisible = false;
                        this.$emit("updated");

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });

                    }
                });
        }
    }
}

</script>

<style lang="scss">
.okraina-cart-coupons {
    margin-bottom: 35px;

    .panel {
        position: relative;
        padding: 16px 24px;
        background-color: var(--color-block-background);
        margin: 0px -8px;
        padding-left: 24px;
        padding-right: 45px;

        .icon-sale {
            display: none;
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--color-discount);
            font-size: 20px;
        }

        .text {
            position: relative;
            font-weight: 600;
            font-size: 18px;
            color: var(--color-text-dark);

            .label {
                display: block;
                font-size: 10px;
                font-weight: 400;
                color: var(--color-control-label)
            }
        }

        .icon-arrow {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--color-text-gray);
        }
    }

}

.okraina-cart-coupons-modal {
    .items {
        margin-bottom: 25px;

        .label {
            font-size: 11px;
            margin-bottom: 10px;
        }
    }

    .item {
        position: relative;
        margin-bottom: 15px;
        padding-left: 30px;
        font-weight: 500;

        &.not-applyed {
            .coupon {
                text-decoration: line-through;
            }
        }

        .status {
            padding-left: 10px;
            font-size: 12px;
        }

        .delete {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            font-weight: 500;

            width: 30px;
            height: 30px;

            .icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 18px;
            }
        }
    }
}
</style>