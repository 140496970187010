<template>
  <div class="okraina-c row">
    <div v-if="region">
      <div class="label-phone">Интернет-магазин</div>
      <div class="phone"><a :href="'tel:' + region.PROPS.PHONE">{{ region.PROPS.PHONE }}</a></div>
    </div>
    <div class="line col-6"></div>
    <div class="social">
      <div v-for="item in socialInfo" :key="item.FIELDS.ID">
        <div @click="onSocialClick(item)" class="fi a url" :class="[item.PROPS.SVG_ICON_MA.VALUE]">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AppLauncher } from "@capacitor/app-launcher";

import { mapGetters } from "vuex";
export default {
  name: "okraina-user-contacts",
  components: {},
  props: {
    socialInfo: Object,
    default() {
      return {}
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      settings: "getSiteSettings",
      regions: "getRegions",
      connection: "getConnection",
      isNative: "isNativePlatform"
    }),

    region() {
      let item = this.regions.find(r => r.PROPS.DOMAIN == this.connection.server);
      return item;
    }
  },
  methods: {

    /**
     * Клик по иконке соцсети
     */
    onSocialClick(item) {

      //если работаем в нативном приложении
      if (this.isNative) {
        this.openNativeUrl(item);
      } else {
        this.openWebUrl(item);
      }

    },

    /**
     * Открывает соцсеть в нативе
     */
    openNativeUrl(item) {

      //пытаемся сразу открыть соцсеть по deeplink
      AppLauncher.openUrl({
        url: item.PROPS.SOCIAL_NETWORK_ACCOUNT_DEEPLINK.VALUE,
      }).then((result) => {
        if (!result.completed) {
          AppLauncher.openUrl({ url: item.PROPS.SOCIAL_NETWORK_ACCOUNT.VALUE });
        }
      });

    },

    /**
     * Открывает соцсеть в веббраузере
     */
    openWebUrl(item) {
      window.open(item.PROPS.SOCIAL_NETWORK_ACCOUNT.VALUE, '_blank', '');
    },

  },
};
</script>

<style lang="scss">
.okraina-c {
  flex-direction: column;
  padding: 20px;
  padding-top: 0px;

  .label-phone {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    margin-bottom: 15px;
  }

  .line {
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .phone {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #35A002;

    a {
      border-bottom: none;
    }
  }

  .social {
    display: flex;

    .url {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      border: none;
      margin-right: 10px;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
  }
}
</style>