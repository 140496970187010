<template>
    <okraina-view :class="'okraina-v-shops'" :refreshDisabled="true" ref="view" :hideMenu="true">
        <template v-slot:header>
            <h1>
                Фирменные магазины
            </h1>
        </template>

        <template v-slot:content>

            <!--Индикатор загрузки-->
            <transition>
                <okraina-loader-page v-show="loading || !canShow" />
            </transition>

            <!-- Ошибка загрузки -->
            <template v-if="loadingError">
                <okraina-illustration :picture="imageError" caption="Ошибка загрузки данных.">
                    <div class="btn btn1 slim" @click="retryLoad">
                        Повторить
                    </div>
                </okraina-illustration>
            </template>


            <!-- Карта -->
            <div class="map" ref="map">
                <div class="loader" v-if="mapLoadig && !loading && !loadingError && canShow">
                    <okraina-loader-inline />
                </div>
            </div>

            <!-- Просмотр магазина-->
            <okraina-sheet v-model:visible="showShop" :maxHeight="90">
                <template v-slot:body>
                    <div class="okraina-v-shops-view">
                        <div class="location">
                            {{ typeof currentShop.PROPS.LOCATION === 'object' ? currentShop.PROPS.LOCATION.VALUE :
                                currentShop.PROPS.LOCATION }}
                        </div>
                        <div class="address">
                            {{ currentShop.PROPS.ADDRESS }}
                        </div>
                        <div class="schedule">
                            {{ currentShop.PROPS.WORKTIME }}<template v-if="currentShop.PROPS.WORKDAYS">, {{
                                currentShop.PROPS.WORKDAYS }}</template>

                        </div>

                        <template v-if="currentShop.FIELDS.PREVIEW_PICTURE || currentShop.FIELDS.DETAIL_PICTURE">
                            <div class="picture preloaded" v-if="currentShop.FIELDS.PREVIEW_PICTURE"
                                v-lazy:background-image="currentShop.FIELDS.PREVIEW_PICTURE.SRC">
                                <div class="preload"
                                    :style="{ backgroundImage: 'url(\'' + currentShop.FIELDS.PREVIEW_PICTURE.PRELOAD + '\')' }">
                                </div>
                                <div class="loader">
                                    <okraina-loader-inline />
                                </div>
                            </div>

                            <div class="picture preloaded" v-else-if="currentShop.FIELDS.DETAIL_PICTURE"
                                v-lazy:background-image="currentShop.FIELDS.DETAIL_PICTURE.SRC">
                                <div class="preload"
                                    :style="{ backgroundImage: 'url(\'' + currentShop.FIELDS.DETAIL_PICTURE.PRELOAD + '\')' }">
                                </div>
                                <div class="loader">
                                    <okraina-loader-inline />
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </okraina-sheet>

        </template>
    </okraina-view>
</template>

<script>
import okrainaView from "@/components/view2.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";
import okrainaIllustration from "@/components/illustration.vue";
import okrainaLoaderInline from "@/components/loader.inline.vue";
import okrainaSheet from "@/components/sheet.vue"

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import errorListiner from '@/plugins/error.listiner'
import ymaps from 'ymaps';
import meta from "@/plugins/meta";
import metaTemplates from "@/plugins/meta/about.shops.js";

import imageErrorPath from "@/assets/images/error.svg";
import imagePlacemarkPath from "@/assets/images/placemark.png";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-shops",
    components: {
        okrainaView,
        okrainaLoaderPage,
        okrainaIllustration,
        okrainaLoaderInline,
        okrainaSheet
    },
    data() {
        return {
            loading: true,
            loadingError: false,
            canShow: false,
            mapLoadig: true,
            items: [],
            mapInited: false,
            imageError: imageErrorPath,
            imagePlacemark: imagePlacemarkPath,
            showShop: false,
            currentShop: false,
            deleteMap: false
        }
    },
    computed: {
        ...mapGetters({
            siteSettings: "getSiteSettings"
        }),

        apiKey() {
            return this.siteSettings ? this.siteSettings.YMAP_KEY : "";
        },

        initCenter() {
            let item = this.items.find(x => !!x);

            if (!item) {
                return false
            }
            return [parseFloat(item.PROPS.LATITUDE), parseFloat(item.PROPS.LONGITUDE)]
        }
    },

    watch: {
        apiKey: function (newVal) {
            if (newVal) {
                this.initMap();
            }
        },
        items: function (newVal) {
            if (newVal) {
                this.initMap();
            }
        },
        canShow: function (newVal) {
            if (newVal) {
                this.initMap();
            }
        }
    },

    methods: {

        /**
         * Загружает данные
        */
        loadData() {
            this.loading = true;

            rest
                .call("company.shops", {
                    method: "post",
                    data: {
                        RESIZE_OPTIONS: {
                            WIDTH: "400",
                            HEIGHT: "300",
                            TYPE: "EXACT",
                            PRELOAD: "Y"
                        },
                    },
                })
                .then((data) => {
                    if (data.SUCCESS) {
                        this.items = data.ITEMS;
                        this.loadingError = false;
                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                        this.loadingError = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Повторная попытка загрузки
         */
        retryLoad() {
            this.loadData();
        },

        /**
         * Инициализация карты
         */
        initMap() {

            if (!this.apiKey || !this.canShow || this.items.length == 0 || this.mapInited) {
                return;
            }

            this.mapInited = true;

            //загруажем API яндекс карт
            ymaps
                .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=' + this.apiKey)
                .then(maps => {

                    this.mapLoadig = false;

                    //создаём точки на карте через кластер
                    let cluster = this.createCluster(maps);
                    let bounds = cluster.getBounds();
                    let boundsResult = maps.util.bounds.getCenterAndZoom(bounds, [this.$refs.map.offsetWidth, this.$refs.map.offsetHeight]);

                    //создаём карту (map Специально в data не заводим, чтобы было НЕ реактивным)
                    this.map = new maps.Map(this.$refs.map, {
                        center: boundsResult.center,
                        zoom: boundsResult.zoom,
                        controls: ['geolocationControl', 'typeSelector', 'zoomControl'],
                        behaviors: ['multiTouch', 'dblClickZoom', 'drag']
                    });
                    this.map.geoObjects.add(cluster);

                })
                .catch(error => {
                    errorListiner.onError(error);
                    console.log('Failed to load Yandex Maps', error);
                });
        },

        /**
         * Создаёт на карте точки
         */
        createCluster(maps) {

            //создаём кластер
            let clusterer = new maps.Clusterer({
                clusterIconColor: "#76B82B",
            });

            this.items.forEach((item) => {

                let placemark = new maps.Placemark(
                    [parseFloat(item.PROPS.LATITUDE), parseFloat(item.PROPS.LONGITUDE)],
                    {

                    },
                    {
                        balloonShadow: false,
                        iconLayout: 'default#image',
                        iconImageHref: this.imagePlacemark,
                        iconImageSize: [40, 40],
                        iconImageOffset: [-(40 / 2), -40]
                    }

                );
                clusterer.add(placemark);

                let vm = this
                placemark.events.add(['click'], function () {
                    vm.onItemClick(item);
                })
            });

            return clusterer;
        },

        /**
         * Клик по магазину на карте
         */
        onItemClick(item) {
            this.currentShop = item;
            this.showShop = true;
        }

    },

    created() {
        this.loadData();

        //задерживаем показ до завершения перехода на экран (чтобы анимация перехода без тормазов была)
        setTimeout(() => {
            this.canShow = true;
        }, 400);
    },

    beforeUnmount() {
        //очистим ресурсы карты
        if (this.map) {
            this.map.destroy();
        }
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter() {
        //запишем мета данные страницы
        meta.write(metaTemplates);

    },
}
</script>

<style lang="scss">
.okraina-v-shops {
    .map {
        position: absolute;
        top: 60px;
        right: 0px;
        left: 0px;
        bottom: 0px;

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.okraina-v-shops-view {
    .location {
        font-size: 16px;
        font-weight: 600;
        color: var(--color-text-dark);
        margin-bottom: 10px;
    }

    .address,
    .schedule {
        font-size: 13px;
    }

    .picture {
        margin-top: 15px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        padding-bottom: 75%;
        padding-top: 0;
        height: 0;
    }
}
</style>
