<template>
    <okraina-view :class="'okraina-v-auth-restore'" :refreshDisabled="true" :hideMenu="true" :contentHorizontalPadding="32">

        <template v-slot:header>
            <h1>
                Восстановление пароля
            </h1>
        </template>

        <template v-slot:content>

            <div class="spacer"></div>

            <okraina-tabs :items="tabs" v-model="selectedTab" />

            <template v-if="selectedTab == 'email'">

                <div class="notice">
                    Если вы забыли пароль, введите логин или E-Mail.
                    Контрольная строка для смены пароля, а также Ваши регистрационные данные, будут высланы вам по E-Mail.
                </div>

                <template v-if="!showSuccessByEmail">
                    <okraina-control-input label="E-Mail" v-model="email" />

                    <okraina-control-input label="или Логин" v-model="login" />

                    <div class="btn btn-block btn1" :class="loading ? 'wait' : ''" v-ripple @click="restoreByEmail">
                        Отправить
                        <div v-if="loading" class="btn-loader"></div>
                    </div>
                </template>

                <template v-else>

                    <div class="success">
                        Контрольная строка, а также ваши регистрационные данные были высланы на email. Пожалуйста, дождитесь
                        письма, так как контрольная строка изменяется при каждом запросе.
                    </div>
                </template>

            </template>

            <template v-else>

                <div class="notice">
                    Если вы забыли пароль, введите номер вашего телефона - мы отправим SMS с новым паролем.
                </div>

                <template v-if="!showSuccessBySMS">
                    <okraina-control-input label="Телефон" v-model="phone" :required="true" v-model:error="phoneError"
                        v-model:valid="phoneValid" :mask="phoneMask" />

                    <okraina-control-input v-if="captchaSid" v-model="captchaWord" type="text" label="Слово на картинке"
                        :captcha="captchaURL" :required="true" v-model:error="captchaError" v-model:valid="captchaValid" />

                    <div class="btn btn-block btn1" :class="loading ? 'wait' : ''" v-ripple @click="restoreBySMS">
                        Отправить
                        <div v-if="loading" class="btn-loader"></div>
                    </div>

                </template>
                <template v-else>

                    <div class="success">
                        Новый пароль успешно отправлен на номер {{ phone }}
                    </div>
                </template>

            </template>


        </template>

    </okraina-view>
</template>


<script>
import okrainaView from "@/components/view2.vue";
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaTabs from "@/components/tabs.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
    name: "okraina-v-auth-restore",
    components: {
        okrainaView,
        okrainaControlInput,
        okrainaTabs
    },
    data() {
        return {
            loading: false,
            email: "",
            login: "",
            showSuccessByEmail: false,

            phone: "",
            phoneValid: false,
            phoneError: false,
            phoneMask: tools.maskPhone(),
            showSuccessBySMS: false,

            captchaWord: "",
            captchaSid: "",
            captchaURL: "",
            captchaValid: false,
            captchaError: false,

            tabs: [
                {
                    id: "email",
                    name: "по E-mail"
                },
                {
                    id: "sms",
                    name: "по SMS"
                }
            ],
            selectedTab: "email"
        };
    },
    computed: {
        ...mapGetters({
            isAuthorized: "isAuthorized",
        }),
    },
    methods: {

        /**
         * Загружает капчу
         */
        loadCaptcha() {

            rest
                .call("system.captcha", {
                    method: "post",
                    data: {},
                })
                .then((data) => {
                    this.captchaSid = data.CAPTCHA_SID;
                    this.captchaURL = data.CAPTCHA_URL;
                });
        },

        /**
         * Восстановление по E-Mail
         */
        restoreByEmail() {

            if (this.loading) {
                return;
            }

            if (!this.checkByEmail()) {
                return;
            }
            this.loading = true;

            rest
                .call("auth.restore", {
                    method: "post",
                    data: {
                        LOGIN: this.login,
                        EMAIL: this.email
                    },
                })
                .then((data) => {
                    //если ошибка
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        this.showSuccessByEmail = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Проверяет поля формы "По E-Mail"
         */
        checkByEmail() {
            if (!this.email && !this.login) {
                bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Необходимо заполнить хотя бы одно поле." });
                return false;
            }

            return true;
        },

        /**
         * Восстановление по SMS
         */
        restoreBySMS() {
            if (this.loading) {
                return;
            }

            if (!this.checkBySMS()) {
                return;
            }
            this.loading = true;

            rest
                .call("auth.restore.sms", {
                    method: "post",
                    data: {
                        PHONE: this.phone,
                        CAPTCHA_WORD: this.captchaWord,
                        CAPTCHA_SID: this.captchaSid
                    },
                })
                .then((data) => {
                    //если ошибка
                    if (!data.SUCCESS) {
                        this.captchaSid = data.CAPTCHA_SID;
                        this.captchaURL = data.CAPTCHA_URL;
                        this.captchaWord = "";

                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        this.showSuccessBySMS = true;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        /**
         * Проверяет поля формы "По СМС"
         */
        checkBySMS(showErrors = true) {

            let error = !this.phoneValid;
            if (showErrors || !error) {
                this.phoneError = error;
            }

            error = !this.captchaValid;
            if (showErrors || !error) {
                this.captchaError = error;
            }

            if (this.captchaError || this.phoneError) {
                return false;
            }

            return true;
        },

        /**
        * Сохраняет данные для восстановления приложения из background
        */
        saveDataForRestore() {
            if (this.isAuthorized) {
                return false;
            }
            return {
                "component": this.$options.name,
                "data": {
                    email: this.email,
                    login: this.login,
                    phone: this.phone,
                    selectedTab: this.selectedTab
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }

    },

    created() {
        //восттановление данных
        this.restoreData();
    },

    mounted() {
        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },

    unmounted() {
        //отписываемся от сохранения данных для восстановления приложения из background
        this.$store.commit("removeRestoreDataCallback", this.saveDataForRestore);
    },

    /**
     * Обраотка переход входом на экран
     */
    beforeRouteEnter(to, from, next) {

        //запишем мета данные страницы
        // meta.write(metaTemplates);

        next((vm) => {
            vm.showSuccessByEmail = false;
            vm.showSuccessBySMS = false;
            vm.loadCaptcha();
        });

    },
};
</script>

<style lang="scss">
.okraina-v-auth-restore {
    .view-outer {
        background-color: var(--color-block-background);
    }

    .view-content {
        padding-top: 15px;
    }

    .notice {
        margin-bottom: 25px;
    }

    .btn-wrap {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .success {
        margin-bottom: 25px;
        padding: 20px;
        text-align: center;
        background-color: var(--color-message-success-background);
        color: var(--color-message-success-text);
        border-radius: 10px;
    }


}
</style>