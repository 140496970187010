<template>
  <div class="okraina-sp" ref="root">
    <div class="title" v-if="title">
      <h2>
        <template v-if="pathToAll">
          <a :href="pathToAll" @click.prevent="goToAll" v-ripple>
            {{ title }}
            <span class="icon fi fi-arrow-right"></span>
          </a>
        </template>
        <template v-else>
          {{ title }}
        </template>

      </h2>

    </div>

    <div class="slider-wrap">
      <div class="slider">

        <div class="slide" v-for="item in items" :key="item.FIELDS.ID">
          <okraina-catalog-item :item="item" :slim="true" :recommendationId="recommendationId" />
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import okrainaCatalogItem from "@/components/catalog/item.vue";



export default {
  name: "okraina-sliders-products",
  components: {
    okrainaCatalogItem,
  },
  data() {
    return {
      swiper: false,
    };
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    pathToAll: {
      type: String,
      default() {
        return "";
      },
    },
    textToAll: {
      type: String,
      default() {
        return "";
      },
    },
    recommendationId: {
      type: [String, Number],
      default() {
        return "";
      }
    }
  },
  methods: {
    goToAll() {
      this.$router.push({ path: this.pathToAll });
    },

    onSwiper(swiper) {
      this.swiper = swiper;
      //this.autoHeight();
    },

    onSideChange() {
      //this.autoHeight();
    },

    autoHeight() {
      let offset = -1 * this.swiper.translate;
      let width = this.$refs.root.clientWidth;

      let height = 0;
      this.swiper.slides.forEach((slide) => {
        if (
          (slide.offsetLeft >= offset && slide.offsetLeft < offset + width) ||
          (slide.offsetLeft < offset &&
            slide.offsetLeft + slide.clientWidth >= offset)
        ) {
          if (height == 0 || slide.clientHeight > height) {
            height = slide.clientHeight;
          }
        }
      });

      let wrappers = this.swiper.el.getElementsByClassName("swiper-wrapper");
      if (wrappers.length > 0) {
        wrappers[0].style.height = height + "px";
      }
    },
  },
};
</script>

<style lang="scss">
.okraina-sp {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;

  .title {
    position: relative;
    padding-right: 100px;

    a {
      border-bottom: 0px;
      color: var(--color-text-dark);

      .icon {
        margin-left: 10px;
        font-size: 12px;
        color: var(--color-color1);
      }
    }


  }

  .slider {

    overflow-x: auto;
    display: flex;
    box-sizing: content-box;

    margin: 0px calc(0px - var(--view-content-hor-padding));
    padding-left: var(--view-content-hor-padding);

    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }


    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .slide {
      padding-top: 5px;
      flex-shrink: 0;
      width: 40vw;
      max-width: 180px;
      margin-right: 8px;
    }
  }



  .swiper {
    overflow: visible;

    .swiper-slide {
      padding-top: 5px;
      width: 130px;
      height: auto;
    }
  }
}
</style>

