import { createStore } from 'vuex'

import storage from '@/plugins/storage'


import bigdata from "@/store/bigdata";
import basket from "@/store/basket";
import favorites from "@/store/favorites";
import connection from "@/store/connection";
import user from "@/store/user";
import settings from "@/store/settings";

import { App } from '@capacitor/app';

export default createStore({
    modules: {
        connection: connection,
        bigdata: bigdata,
        basket: basket,
        favorites: favorites,
        user: user,
        settings: settings
    },
    state: {
        
        online: true,
        //переменная, отражающая что в данный момент идёт переход назад
        isNowGoBack: false,
        //скрыть меню
        hideMenu: false,
        //меню приложения
        menu: [
            {
                type: "home",
                icon: "fi-home",
                name: "Главная",
                ext: []
            },
            {
                type: "catalog",
                icon: "fi-catalog",
                name: "Каталог",
                ext: [
                    'catalog-section',
                    'catalog-search',
                    'catalog-product',
                    'catalog-consist'
                ]
            },
            {
                type: "bonuses",
                icon: "fi-barcode-scanner",
                name: "Моя карта",
                ext: []
            },
            {
                type: "cart",
                icon: "fi-cart",
                name: "Корзина",
                ext: []
            },
            {
                type: "menu",
                icon: "fi-profile",
                name: "Профиль",
                ext: []
            },
        ],
        
        //стек вызова для действия "Назад"
        backStack: [],

        //безопасная область вывода (для учёта шторки)
        safeArea: false,
        //платформа (web,android,ios)
        platform: false,
        //версия нативного приложения
        appVersion: false,
        //номер билда нативного приложения
        appBuild: false,
        //производитель устройства
        manufacturer:"",

        //токен PUSH
        pushToken: null,
         //флаг приняти правил использования
        termsOfUseAccepted: false,
        //флаг готовности приложения (после того как App смонтируется)
        appReady: false,
        //обработка пуш (приложение обрабатывает полученную пуш и совершает переход к нужному экрану)
        pushClicked: false,
        //данные для восстановления приложения после background
        restoreDataCallbacks: [],
        restoreData: [],
        restorePluginResult: false,
        
    },
    getters: {

        getOnline(state) {
            return state.online;
        },
        getIsNowGoBack(state) {
            return state.isNowGoBack;
        },
        isHideMenu(state) {
            return state.hideMenu;
        },
        
        getMenu(state) {
            return state.menu;
        },

        isRouteInMenu: state => name => {
            return state.menu.find(item => item.type === name) !== undefined;
        },
       
        /**
         * Возвращает стэк функций-обработчиков возврата назад
         */
        getBackStack(state) {
            return state.backStack;
        },

        getSafeArea(state) {
            return state.safeArea;
        },

        /**
         * Платформа (web,android,ios)
         */
        getPlatform(state) {
            return state.platform;
        },

        /**
         * Это нативная платфомра
         */
        isNativePlatform(state) {
            return state.platform == "android" || state.platform == "ios"
        },

        /**
         * Версия нативного приложения
         */
        getAppVersion(state) {
            return state.appVersion;
        },

        /**
         * Версия билда нативного приложения
         */
        getAppBuild(state) {
            return state.appBuild;
        },

        /**
         * Производитель нативного приложения
         */
        getManufacturer(state) {
            return state.manufacturer;
        },

        /**
         * Пуш токен
         */
        getPushToken(state) {
            return state.pushToken;
        },

        /**
         * Флаг принятия правил использования
         */
        getTermsOfUseAccepted(state) {
            return state.termsOfUseAccepted;
        },

        /**
         * Флаг готовности приложения
         */
        getAppReady(state) {
            return state.appReady;
        },

        /**
         * Флаг клика по пуш
         */
        getPushClicked(state) {
            return state.pushClicked;
        },

        /**
         * Получает список колбэков для сохранения данных для восстановления
         */
        getRestoreDataCallbacks(state) {
            return state.restoreDataCallbacks;
        },

        /**
         * Получает данные для восстановления
         */
        getRestoreData(state) {
            return state.restoreData;
        },

        /**
         * Получает результат работы плагина Capacitor при восстановлении приложения
         */
        getRestorePluginResult(state) {
            return state.restorePluginResult;
        }

    },
    mutations: {
        
        setOnline: (state, value) => {
            state.online = value;
        },
        setIsNowGoBack: (state, value) => {
            state.isNowGoBack = value;
        },
        setHideMenu: (state, value) => {
            state.hideMenu = value;
        },
       
        /**
         * Добавляет метод в стек обработчика возврата назхад
         */
        addToBackStack: (state, value) => {
            state.backStack.push(value);
        },

        /**
         * Удаляет метод из стека обработчиков возврата назад
         */
        removeFromBackStack: (state, value) => {
            state.backStack = state.backStack.filter(f => f != value);
        },

        /**
         * Устанавливает данные о безопасной зоне отображения
         */
        setSafeArea: (state, value) => {
            state.safeArea =value;

            for (var key in value) {

                document.documentElement.style.setProperty(
                    `--safe-area-${key}`,
                    `${value[key]}px`,
                );
            }
        },

        /**
         * Устанавливает данные о платформе в которой работает приложение
         */
        setPlatform:(state,value) => {
            state.platform = value;
        },  

        /**
         * Устанавливает версиб нативного приложения
         */
        setAppVersion: (state, value) => {
            state.appVersion = value;
        },

        /**
         * Устанавливает версию билда нативного приложения
         */
        setAppBuild: (state, value) => {
            state.appBuild = value;
        },

        /**
         * Устанавливает производителя нативного приложения
         */
        setManufacturer: (state, value) => {
            state.manufacturer = value;
        },
    
        /**
         * Устанавливает Push-токен
         */
        setPushToken: (state, value) => {
            state.pushToken = value;
            storage.set("push_token", value);
        },

        /**
         * Устанавилвает факт принятия правил использования
         */
        setTermsOfUseAccepted: (state, value) => {
            state.termsOfUseAccepted = value;
            if(value) {
                storage.set("terms_of_use_accepted", "Y");
            }
        },

        /**
         * Устанавливает флаг готовности приложения
         */
        setAppReady: (state,value) => {
            state.appReady = value;
        },

        /**
         * Устанавливает флаг клика по пуш
         */
        setPushClicked: (state,value) => {
            state.pushClicked = value;
        },

        /**
         * Добавляет данные для сохранения для посследующего восставления приложения из background
         */
        addRestoreDataCallback: (state, value) => {
            state.restoreDataCallbacks.push(value);
        },

         /**
         * Удаляет метод сохранения данных для восстановления
         */
        removeRestoreDataCallback: (state, value) => {
            state.restoreDataCallbacks = state.restoreDataCallbacks.filter(f => f != value);
        },

        /**
         * Устанавливает данные для восстановления
         */
        setRestoreData: (state,value) => {
            state.restoreData = value;
        },

        /**
         * Устанавливает результат работы плагина Capacitor при восстановлении приложения
         */
        setRestorePluginResult: (state,value) => {
            state.restorePluginResult = value;
        },
    },

    actions: {

        /**
         * Загружает данные о нативном приложении
         */
        loadAppInfo(context) {
            App.getInfo().then((info) => {
                context.commit('setAppVersion', info.version);
                context.commit('setAppBuild',info.build);
            });
        }
    }
})